import HadithResultItem from '@components/results/items/HadithResultItem';

import { Cancel as CancelIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import { GenericHadith } from 'shared/interfaces/hadith';

interface OriginalHadithModalProps {
    /**
     * Function to close the modal
     */
    onClose: () => void;
    /**
     * The hadith object to be displayed in HadithResultItem
     */
    originalHadith: GenericHadith;
}

const OriginalHadithModal = ({
    onClose,
    originalHadith,
}: OriginalHadithModalProps) => {
    return (
        <Dialog open onClose={onClose} dir="rtl" fullWidth={true} maxWidth="lg">
            <DialogTitle
                display="flex"
                alignItems={'center'}
                justifyContent="end"
            >
                <IconButton onClick={onClose}>
                    <CancelIcon
                        sx={{
                            color: '#B7A4A4',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        fontSize="large"
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <HadithResultItem
                    hadith_object={originalHadith}
                    rightActions={[]}
                    allowNarratorClick={false}
                    turnOffLeftActions
                />
            </DialogContent>
        </Dialog>
    );
};

export default OriginalHadithModal;
