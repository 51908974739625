export const authenticity_order = {
    'صحيح البخاري': 1,
    'صحيح مسلم': 2,
    'سنن أبي داود': 7,
    'جامع الترمذي': 8,
    'سنن النسائى الصغرى': 4,
    'سنن ابن ماجه': 9,
    'موطأ مالك رواية يحيى الليثي': 3,
    'سنن الدارمي': 10,
    'مسند أحمد بن حنبل': 11,
    'صحيح ابن حبان': 6,
    'صحيح ابن خزيمة': 5,
    'المستدرك على الصحيحين': 12,
} as const;

export const ruler_order = {
    الشافعي: 204,
    'يحيى بن معين': 233,
    'علي ابن المديني': 234,
    'أحمد بن حنبل': 241,
    البخاري: 256,
    مسلم: 261,
    'أبو زرعة الرازي': 264,
    'أبو داود السجستاني': 275,
    'أبو حاتم الرازي': 277,
    الترمذي: 279,
    النسائي: 303,
    'ابن خزيمة': 311,
    'ابن حبان': 354,
    الدارقطني: 385,
    الخطابى: 388,
    الحاكم: 405,
    'ابن حزم': 456,
    البيهقي: 458,
    'ابن عبد البر': 463,
    'عبد الحق الإشبيلي': 581,
    'ابن الجوزي': 597,
    'ابن القطان الفاسي': 628,
    المنذري: 656,
    النووي: 676,
    'ابن دقيق العيد': 702,
    'ابن الملقن': 804,
    'ابن حجر': 852,
    'محمد بن عبد الباقي الزرقاني': 1122,
    الألباني: 1420,
    'شعيب الأرنؤوط': 1437,
    'حسين أسد': 1440,
    'ماهر الفحل': 1445,
};

export const explanation_authenticity_order = {
    'فتح الباري بشرح صحيح البخاري': 1,
    'فتح الباري في شرح صحيح البخاري': 2,
    'عمدة القاري شرح صحيح البخاري': 3,
    'التوضيح لشرح الجامع الصحيح': 4,
    'المنهاج شرح صحيح مسلم بن الحجاج': 5,
    'السراج الوهاج من كشف مطالب صحيح مسلم بن الحجاج': 6,
    'المفهم لما أشكل من تلخيص كتاب مسلم': 7,
    'شرح الزرقاني على موطأ الإمام مالك': 8,
    'التمهيد لما في الموطأ من المعاني والأسانيد': 9,
    'الاستذكار الجامع لمذاهب فقهاء الأمصار وعلماء الأقطار': 10,
    'شرح السيوطي لسنن النسائي': 11,
    'عون المعبود شرح سنن أبي داود': 12,
    'تهذيب سنن أبي داود': 13,
    'تحفة الأحوذي بشرح جامع الترمذي': 14,
    'حاشية السندي على ابن ماجه': 15,
    'حاشية السندي على المسند': 16,
} as const;

export const narr_comm_auth_order = {
    'الجرح والتعديل لابن أبي حاتم': 1,
    'الكامل في ضعفاء الرجال': 2,
    'تقريب التهذيب': 3,
    'تهذيب التهذيب': 4,
    'تهذيب الكمال في أسماء الرجال': 5,
};

const time_order = {
    'موطأ مالك رواية يحيى الليثي': 179,
    'مسند أحمد بن حنبل': 241,
    'سنن الدارمي': 255,
    'صحيح البخاري': 256,
    'صحيح مسلم': 261,
    'سنن ابن ماجه': 303,
    'سنن أبي داود': 275,
    'جامع الترمذي': 279,
    'سنن النسائى الصغرى': 303,
    'صحيح ابن حبان': 354,
    'صحيح ابن خزيمة': 311,
    'المستدرك على الصحيحين': 405,
} as const;

export type HadithBook = {
    key: keyof typeof authenticity_order;
    doc_count: number;
};

export type HadithBookTitle = keyof typeof authenticity_order;

export type HadithExplanationBook = {
    key: keyof typeof explanation_authenticity_order;
    doc_count: number;
};

export const sortByAuthenticity = (array: HadithBook[]) => {
    return array.sort((a, b) =>
        authenticity_order[a.key] > authenticity_order[b.key]
            ? 1
            : authenticity_order[a.key] < authenticity_order[b.key]
            ? -1
            : 0,
    );
};

export const sortBookTitlesByAuthenticity = (
    array: HadithBookTitle[],
): HadithBookTitle[] => {
    return [...array].sort((a, b) =>
        authenticity_order[a] > authenticity_order[b]
            ? 1
            : authenticity_order[a] < authenticity_order[b]
            ? -1
            : 0,
    );
};

export const sortByExplanationAuthenticity = (
    array: HadithExplanationBook[],
) => {
    return array.sort((a, b) =>
        explanation_authenticity_order[a.key] >
        explanation_authenticity_order[b.key]
            ? 1
            : explanation_authenticity_order[a.key] <
              explanation_authenticity_order[b.key]
            ? -1
            : 0,
    );
};

export const sortByLatest = (array: HadithBook[]) => {
    return array.sort((a, b) =>
        time_order[a.key] < time_order[b.key]
            ? 1
            : time_order[a.key] > time_order[b.key]
            ? -1
            : 0,
    );
};

export const sortByEarliest = (array: HadithBook[]) => {
    return array.sort((a, b) =>
        time_order[a.key] > time_order[b.key]
            ? 1
            : time_order[a.key] < time_order[b.key]
            ? -1
            : 0,
    );
};
