import type { NextRouter } from 'next/router';

import { HadithViews, NarratorViews } from '@constants/views';
import { ROUTING } from '@hooks/useParams';
import { Tooltip, Typography } from '@mui/material';
import { NarratorColor } from '@utils/narratorColor';

import { type HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { Narrator } from 'shared/interfaces/hadith';
import { colors } from 'theme';

export function getPrettySubChapter(subChapter: string) {
    let prettySubChapter = '';
    if (subChapter) {
        let counter = 0;
        prettySubChapter =
            subChapter.includes('/30') || subChapter.includes('/65')
                ? subChapter.replace(/\/(30|65)/g, () => {
                      counter++;
                      return counter % 2 === 1 ? '⟪⟪' : '⟫⟫';
                  })
                : subChapter.includes('/60')
                ? subChapter.replace(/\/(60)/g, () => {
                      counter++;
                      return counter % 2 === 1 ? '' : '';
                  })
                : subChapter;
    }

    return prettySubChapter;
}

export function getHadithSubtitle(chapter: string, subChapter: string) {
    let prettySubChapter = getPrettySubChapter(subChapter);

    if (chapter) {
        if (prettySubChapter && prettySubChapter != chapter) {
            return `${chapter} ${
                prettySubChapter ? `- ${prettySubChapter}` : ''
            }`;
        }
        return chapter;
    } else {
        return prettySubChapter;
    }
}

export function getBookAndIdText(
    book_name: string,
    first_hadith_number: number | string | undefined,
): string {
    return first_hadith_number
        ? `${book_name} - ${first_hadith_number}`
        : book_name;
}

export function getBookAndIdTextToggle(
    bookOn: boolean,
    numberOn: boolean,
    book_name: string,
    first_hadith_number: number,
): string {
    return bookOn && numberOn
        ? `${book_name} - ${first_hadith_number}`
        : bookOn
        ? book_name
        : numberOn
        ? `${first_hadith_number}`
        : '';
}

export function getVolumeAndPageText(
    volumeOn: boolean,
    pageOn: boolean,
    volume: number | null | undefined,
    page: number | null | undefined,
): string {
    return pageOn && volumeOn
        ? `جزء: ${volume} - صفحة: ${page}\n`
        : pageOn
        ? `صفحة: ${page}\n`
        : volumeOn
        ? `جزء: ${volume}\n`
        : '';
}

export function getHadithWithTashkeelToggled(
    tashkeelOn: boolean,
    hadithHtml: string,
): string {
    return tashkeelOn
        ? // this regex removes all html tags
          hadithHtml.replace(/<\/?[^>]+(>|$)/g, '')
        : hadithHtml
              // this regex identifies tashkeel
              .replaceAll(/[\u064b-\u0652]/g, '')
              // this regex removes all html tags
              .replace(/<\/?[^>]+(>|$)/g, '');
}

export function getHadithWithTarqeemToggled(
    tarqeemOn: boolean,
    hadithHtml: string,
): string {
    return tarqeemOn
        ? // this regex removes all html tags
          hadithHtml.replace(/<\/?[^>]+(>|$)/g, '')
        : hadithHtml
              // this regex identifies tarqeem
              .replaceAll(/\s*[،:.]\s*/g, ' ')
              // this regex removes all html tags
              .replace(/<\/?[^>]+(>|$)/g, '');
}

const getNarratorData = (narrators: Narrator[], narratorId: string) => {
    const narrator = narrators.find((narrator) => narrator.id === narratorId);
    if (narrator) {
        return narrator;
    }
    // unexpected data quality issue
    throw new Error(
        `Unexpected error: Narrator in Hadith HTML not present in narrators list ${narratorId}`,
    );
};

type TextNode = { type: 'text'; data: string };

function isTextNode(node: any): node is TextNode {
    return node.type === 'text';
}

export const htmlToReactParserOptions = (
    narrators: Narrator[],
    narratorsColors: NarratorColor[],
    router: NextRouter,
    onNarratorClick?: (
        narratorId: string,
        narratorView: NarratorViews | undefined,
    ) => void,
    onAmbiguousClick?: (ambiguousId: number) => void,
    routing?: ROUTING,
    selectedAmbiguousId?: string,
    fontSize?: string,
): HTMLReactParserOptions => {
    const hadithOptions: HTMLReactParserOptions = {
        replace: (node) => {
            if (isTextNode(node)) {
                // Apply font size to text nodes
                return <span style={{ fontSize }}>{node.data}</span>;
            }

            if (!('name' in node && 'attribs' in node)) {
                // only process 'Element' node
                return;
            }
            const { name, attribs, children } = node;
            // Matn component
            if (name === 'a' && attribs.class === 'matn') {
                return (
                    <span
                        style={{
                            color: '#a00000',
                            fontWeight: 500,
                            fontSize: fontSize,
                        }}
                    >
                        {domToReact(children, hadithOptions)}
                    </span>
                );
            }

            // Gharib Hadith
            if (name === 'span' && attribs.class === 'ambiguous') {
                const ambiguousId = Number(attribs.id);

                return (
                    <Typography
                        component={'span'}
                        onClick={() =>
                            onAmbiguousClick && onAmbiguousClick(ambiguousId)
                        }
                        sx={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: colors.tertiary,
                            '&: hover': {
                                textDecoration: 'underline',
                            },
                            backgroundColor:
                                router.query['hadithView'] ===
                                    HadithViews.AMBIGUOUS_WORDS &&
                                selectedAmbiguousId === ambiguousId.toString()
                                    ? 'rgba(255, 255, 0, 0.9)'
                                    : '',
                            padding: '7px 5px',
                            borderRadius: '10px',
                            fontSize: fontSize,
                        }}
                    >
                        {domToReact(children, hadithOptions)}
                    </Typography>
                );
            }

            // Narrator component
            if (name === 'a' && attribs.class === 'rawy') {
                const narratorId = attribs.id;

                const { full_name, grade, is_companion } = getNarratorData(
                    narrators,
                    narratorId,
                );

                const narratorColor = narratorsColors.find(
                    ({ key }) => key === full_name,
                )?.color;

                const getColor = () => {
                    if (narratorColor) {
                        return narratorColor;
                    }

                    if (is_companion) {
                        return '#C7956D';
                    }

                    return '#121212';
                };

                const narratorIdKey =
                    routing === ROUTING.NARRATOR_PAGE
                        ? 'narratorId'
                        : 'hadithNarratorId';

                // we're using hadithNarratorView always
                // because the helper should open in a tab in the modal
                // independent of the selected tab in the narrators page
                // so if narrators page tab is "students", this will open
                // in bio. But if the hadith modal tab is "students", this will open
                // in students. So indpendence of the modal and the background /narrators page
                const narratorView = router.query['hadithNarratorView'] as
                    | NarratorViews
                    | undefined;

                return (
                    <Tooltip
                        title={`${full_name} ${
                            grade && grade.trim() !== ''
                                ? ` : ${grade} ( ابن حجر [ت ت] )`
                                : ''
                        }`}
                        placement="top"
                    >
                        <Typography
                            fontSize="inherit"
                            component="span"
                            onClick={() =>
                                onNarratorClick &&
                                onNarratorClick(narratorId, narratorView)
                            }
                            sx={{
                                color: getColor(),
                                fontWeight: 600,
                                fontSize: fontSize,
                                textDecoration: narratorColor
                                    ? 'underline'
                                    : '',
                                '&:hover': {
                                    cursor: onNarratorClick
                                        ? 'pointer'
                                        : undefined,
                                },
                                backgroundColor:
                                    router.query['hadithView'] ===
                                        HadithViews.NARRATOR &&
                                    router.query[narratorIdKey] === narratorId
                                        ? 'rgba(255, 255, 0, 0.9)'
                                        : '',
                                padding: '7px 5px',
                                borderRadius: '10px',
                            }}
                        >
                            {domToReact(children, hadithOptions)}
                        </Typography>
                    </Tooltip>
                );
            }

            if (name === 'highlight') {
                return (
                    <span
                        style={{
                            backgroundColor: 'rgba(255, 255, 0, 0.9)',
                            padding: '7px 5px',
                            borderRadius: '10px',
                            fontSize: fontSize,
                        }}
                    >
                        {domToReact(children, hadithOptions)}
                    </span>
                );
            }

            if (name === 'highlight-light') {
                return (
                    <span
                        style={{
                            backgroundColor: 'rgba(255, 255, 0, 0.3)',
                            padding: '7px 5px',
                            borderRadius: '10px',
                            fontSize: fontSize,
                        }}
                    >
                        {domToReact(children, hadithOptions)}
                    </span>
                );
            }

            if (name === 'highlight-cyan') {
                return (
                    <span
                        style={{
                            backgroundColor: 'rgba(0, 255, 255, 0.3)',
                            padding: '7px 5px',
                            borderRadius: '10px',
                            fontSize: fontSize,
                        }}
                    >
                        {domToReact(children, hadithOptions)}
                    </span>
                );
            }
        },
    };

    return hadithOptions;
};
