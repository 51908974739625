export const PersonIcon = () => {
    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            style={{
                marginBottom: '8px',
            }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.0045 12.0002C15.2466 12.0002 16.2534 13.007 16.2534 14.2491V14.8245C16.2534 15.7188 15.9338 16.5836 15.3523 17.263C13.7829 19.0965 11.3957 20.0013 8.25036 20.0013C5.1045 20.0013 2.71849 19.0962 1.15219 17.2619C0.572425 16.583 0.253906 15.7195 0.253906 14.8267V14.2491C0.253906 13.007 1.26076 12.0002 2.50278 12.0002H14.0045ZM14.0045 13.5002H2.50278C2.08919 13.5002 1.75391 13.8355 1.75391 14.2491V14.8267C1.75391 15.3624 1.94502 15.8805 2.29287 16.2878C3.54618 17.7555 5.51206 18.5013 8.25036 18.5013C10.9887 18.5013 12.9563 17.7555 14.2127 16.2876C14.5617 15.8799 14.7534 15.361 14.7534 14.8245V14.2491C14.7534 13.8355 14.4181 13.5002 14.0045 13.5002ZM8.25036 0.00488281C11.0118 0.00488281 13.2504 2.24346 13.2504 5.00488C13.2504 7.76631 11.0118 10.0049 8.25036 10.0049C5.48894 10.0049 3.25036 7.76631 3.25036 5.00488C3.25036 2.24346 5.48894 0.00488281 8.25036 0.00488281ZM8.25036 1.50488C6.31737 1.50488 4.75036 3.07189 4.75036 5.00488C4.75036 6.93788 6.31737 8.50488 8.25036 8.50488C10.1834 8.50488 11.7504 6.93788 11.7504 5.00488C11.7504 3.07189 10.1834 1.50488 8.25036 1.50488Z"
                fill="#B7A4A4"
            />
        </svg>
    );
};

export const PersonFilledIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 17 20"
            fill="none"
            style={{
                marginBottom: '8px',
            }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.0045 12.0002C15.2466 12.0002 16.2534 13.007 16.2534 14.2491V15.1675C16.2534 15.7409 16.0742 16.2999 15.7408 16.7664C14.1949 18.9296 11.6706 20.0013 8.25036 20.0013C4.8294 20.0013 2.30643 18.9292 0.764274 16.7648C0.432312 16.2989 0.253906 15.7411 0.253906 15.169V14.2491C0.253906 13.007 1.26076 12.0002 2.50278 12.0002H14.0045ZM8.25036 0.00488281C11.0118 0.00488281 13.2504 2.24346 13.2504 5.00488C13.2504 7.76631 11.0118 10.0049 8.25036 10.0049C5.48894 10.0049 3.25036 7.76631 3.25036 5.00488C3.25036 2.24346 5.48894 0.00488281 8.25036 0.00488281Z"
                fill="#C7956D"
            />
        </svg>
    );
};
