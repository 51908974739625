import { env } from '@constants/env';

export type VolumesStartEnd = Record<number, [number, number]>;

export interface BookInfo {
    bookName: string;
    bookAuthor: string;
    bookCopy: string;
    bookPath: string;
    totalVolume: string;
    volumesPage: VolumesStartEnd;
    editions?: Record<string, Omit<BookInfo, 'editions'>>;
}

export const booksBasePath = `https://storage.googleapis.com/gcs-${env}-euw1-books-01/books`;

export const booksConfig: Record<string, BookInfo> = {
    'العلل لابن أبي حاتم': {
        bookName: 'العلل لابن أبي حاتم',
        bookAuthor: 'عبد الرحمن بن أبي حاتم',
        bookCopy: 'مؤسسة الجريسي، الطبعة الأولى، ١٤٢٧ هـ - ٢٠٠٦ م',
        bookPath: 'commentary/abihatem',
        totalVolume: '7',
        volumesPage: {
            1: [1, 656],
            2: [1, 630],
            3: [1, 724],
            4: [1, 724],
            5: [1, 700],
            6: [1, 664],
            7: [1, 566],
        },
    },
    'علل الدارقطني': {
        bookName: 'علل الدارقطني',
        bookAuthor: 'أبو الحسن الدارقطني',
        bookCopy: 'دار طيبة 1405 1/12 - دار ابن الجوزي 1427 13/16',
        bookPath: 'commentary/darqutny',
        totalVolume: '16',
        volumesPage: {
            1: [1, 373],
            2: [1, 340],
            3: [1, 367],
            4: [1, 562],
            5: [1, 426],
            6: [1, 392],
            7: [1, 358],
            8: [1, 404],
            9: [1, 459],
            10: [1, 471],
            11: [1, 434],
            12: [1, 458],
            13: [1, 496],
            14: [1, 454],
            15: [1, 445],
            16: [1, 754],
        },
    },
    'صحيح البخاري': {
        bookName: 'صحيح البخاري',
        bookAuthor: 'محمد بن اسماعيل البخاري',
        bookCopy: 'دار طوق النجاة - بيروت - الطبعة الأولى 1422هـ',
        bookPath: 'hadith/146',
        totalVolume: '9',
        volumesPage: {
            1: [1, 189],
            2: [1, 197],
            3: [1, 218],
            4: [1, 220],
            5: [1, 188],
            6: [1, 215],
            7: [1, 184],
            8: [1, 189],
            9: [1, 175],
        },
        editions: {
            'دار ابن كثير - 1414هـ / 1993م': {
                bookName: 'صحيح البخاري',
                bookAuthor: 'محمد بن اسماعيل البخاري',
                bookCopy:  'دار ابن كثير - 1414هـ / 1993م',
                bookPath: 'hadith/146-1993',
                totalVolume: '1',
                volumesPage: {
                    1: [1, 3172],
                },
            },
        },
    },
    'صحيح مسلم': {
        bookName: 'صحيح مسلم',
        bookAuthor: 'مسلم بن الحجاج',
        bookCopy: 'دار الجيل - بيروت  1334 هـ',
        bookPath: 'hadith/158',
        totalVolume: '8',
        volumesPage: {
            1: [1, 202],
            2: [1, 220],
            3: [1, 180],
            4: [1, 223],
            5: [1, 206],
            6: [1, 188],
            7: [1, 196],
            8: [1, 256],
        },
        editions: {
            'دار إحياء الكتب العربية ١٣٧٤ هـ - ١٩٥٥ م': {
                bookName: 'صحيح مسلم',
                bookAuthor: 'مسلم بن الحجاج',
                bookCopy:  'دار إحياء الكتب العربية ١٣٧٤ هـ - ١٩٥٥ م',
                bookPath: 'hadith/158-ihyaa',
                totalVolume: '1',
                volumesPage: {
                    1: [1, 2324],
                },
            },
        },
    },
    'سنن أبي داود': {
        bookName: 'سنن أبي داود',
        bookAuthor: 'أبو داود السجستاني',
        bookCopy: 'المكتبة العصرية',
        bookPath: 'hadith/184',
        totalVolume: '4',
        volumesPage: {
            1: [1, 320],
            2: [1, 344],
            3: [1, 383],
            4: [1, 380],
        },
    },
    'جامع الترمذي': {
        bookName: 'جامع الترمذي',
        bookAuthor: 'محمد بن عيسى الترمذي',
        bookCopy: 'دار الغرب الإسلامي - بيروت - لبنان - 1996 : 1998م',
        bookPath: 'hadith/195',
        totalVolume: '6',
        volumesPage: {
            1: [1, 622],
            2: [1, 612],
            3: [1, 652],
            4: [1, 576],
            5: [1, 576],
            6: [1, 911],
        },
    },
    'سنن النسائى الصغرى': {
        bookName: 'سنن النسائى الصغرى',
        bookAuthor: 'أبو عبد الرحمن النسائي',
        bookCopy: 'مكتب المطبوعات الإسلامية - 1414هـ / 1994م',
        bookPath: 'hadith/319',
        totalVolume: '9',
        volumesPage: {
            1: [1, 309],
            2: [1, 255],
            3: [1, 277],
            4: [1, 232],
            5: [1, 286],
            6: [1, 288],
            7: [1, 332],
            8: [1, 348],
            9: [1, 367],
        },
    },
    'سنن ابن ماجه': {
        bookName: 'سنن ابن ماجه',
        bookAuthor: 'ابن ماجه أبو عبد الله',
        bookCopy: 'دار الرسالة العالمية - الطبعة الأولى 1430هـ - 2009م',
        bookPath: 'hadith/173',
        totalVolume: '5',
        volumesPage: {
            1: [1, 526],
            2: [1, 676],
            3: [1, 720],
            4: [1, 750],
            5: [1, 750],
        },
    },
    'سنن الدارمي': {
        bookName: 'سنن الدارمي',
        bookAuthor: 'أبو محمد عبد الله الدارمي',
        bookCopy: 'دار المغني - الطبعة الأولى 1412هـ - 2000م',
        bookPath: 'hadith/137',
        totalVolume: '4',
        volumesPage: {
            1: [1, 753],
            2: [754, 1382],
            3: [1383, 1883],
            4: [1884, 2440],
        },
    },
    'موطأ مالك رواية يحيى الليثي': {
        bookName: 'موطأ مالك رواية يحيى الليثي',
        bookAuthor: 'مالك بن أنس',
        bookCopy: 'مؤسسة زايد بن سلطان - الطبعة الأولى 1425هـ - 2004م',
        bookPath: 'hadith/19',
        totalVolume: '8',
        volumesPage: {
            1: [1, 463],
            2: [1, 406],
            3: [407, 788],
            4: [789, 1120],
            5: [1121, 1463],
            6: [1, 359],
            7: [1, 364],
            8: [365, 766],
        },
    },
    'مسند أحمد بن حنبل': {
        bookName: 'مسند أحمد بن حنبل',
        bookAuthor: 'أحمد بن حنبل',
        bookCopy:
            'جمعية المكنز الإسلامي- دار المنهاج - الطبعة الأولى 1431هـ - 2010م',
        bookPath: 'hadith/121',
        totalVolume: '12',
        volumesPage: {
            1: [1, 470],
            2: [471, 1023],
            3: [1024, 1621],
            4: [1622, 2266],
            5: [2267, 2841],
            6: [2842, 3489],
            7: [3490, 4088],
            8: [4089, 4613],
            9: [4614, 5133],
            10: [5134, 5697],
            11: [5698, 6189],
            12: [6190, 6753],
        },
        editions: {
            'دار الرسالة العالمية - الطبعة الأولى ٢٠٠١ - ١٤٢١': {
                bookName: 'مسند أحمد تخريج شعيب الأرنؤوط',
                bookAuthor: 'شعيب الأرنؤوط',
                bookCopy: 'دار الرسالة العالمية - الطبعة الأولى ٢٠٠١ - ١٤٢١',
                bookPath: 'ruling/ahmad_shuaib',
                totalVolume: '50',
                volumesPage: {
                    1: [1, 575],
                    2: [1, 487],
                    3: [1, 526],
                    4: [1, 516],
                    5: [1, 496],
                    6: [1, 433],
                    7: [1, 462],
                    8: [1, 528],
                    9: [1, 525],
                    10: [1, 538],
                    11: [1, 711],
                    12: [1, 520],
                    13: [1, 548],
                    14: [1, 559],
                    15: [1, 554],
                    16: [1, 646],
                    17: [1, 491],
                    18: [1, 446],
                    19: [1, 500],
                    20: [1, 467],
                    21: [1, 512],
                    22: [1, 471],
                    23: [1, 453],
                    24: [1, 511],
                    25: [1, 517],
                    26: [1, 404],
                    27: [1, 386],
                    28: [1, 659],
                    29: [1, 650],
                    30: [1, 638],
                    31: [1, 575],
                    32: [1, 568],
                    33: [1, 476],
                    34: [1, 595],
                    35: [1, 525],
                    36: [1, 661],
                    37: [1, 547],
                    38: [1, 568],
                    39: [1, 589],
                    40: [1, 515],
                    41: [1, 525],
                    42: [1, 523],
                    43: [1, 458],
                    44: [1, 636],
                    45: [1, 633],
                    46: [1, 472],
                    47: [1, 492],
                    48: [1, 502],
                    49: [1, 464],
                    50: [1, 559],
                },
            },
        },
    },
    'صحيح ابن حبان': {
        bookName: 'صحيح ابن حبان',
        bookAuthor: 'ابن حبان البستي',
        bookCopy:
            'مؤسسة الرسالة - بيروت - لبنان - الطبعة الثانية 1414هـ - 1993م',
        bookPath: 'hadith/454',
        totalVolume: '18',
        volumesPage: {
            1: [1, 560],
            2: [1, 544],
            3: [1, 508],
            4: [1, 661],
            5: [1, 651],
            6: [1, 525],
            7: [1, 526],
            8: [1, 450],
            9: [1, 520],
            10: [1, 558],
            11: [1, 620],
            12: [1, 511],
            13: [1, 510],
            14: [1, 603],
            15: [1, 568],
            16: [1, 539],
            17: [1, 601],
            18: [1, 435],
        },
    },
    'صحيح ابن خزيمة': {
        bookName: 'صحيح ابن خزيمة',
        bookAuthor: 'محمد بن إسحاق بن خزيمة',
        bookCopy:
            'دار الميمان - الرياض - السعودية - الطبعة الأولى 1430هـ - 2009م',
        bookPath: 'hadith/345',
        totalVolume: '6',
        volumesPage: {
            1: [1, 740],
            2: [1, 582],
            3: [1, 616],
            4: [1, 611],
            5: [1, 478],
            6: [1, 436],
        },
    },
    'المستدرك على الصحيحين': {
        bookName: 'المستدرك على الصحيحين',
        bookAuthor: 'أبو عبد الله الحاكم النيسابوري',
        bookCopy: 'دار المعرفة - بيروت - لبنان',
        bookPath: 'hadith/594',
        totalVolume: '5',
        volumesPage: {
            1: [1, 598],
            2: [1, 650],
            3: [1, 676],
            4: [1, 640],
            5: [1, 632],
        },
    },
    'تهذيب التهذيب': {
        bookName: 'تهذيب التهذيب',
        bookAuthor: 'ابن حجر العسقلاني',
        bookCopy: 'مؤسسة الرسالة',
        bookPath: 'narrators_commentary/tahzeebtahzeeb',
        totalVolume: '5',
        volumesPage: {
            1: [1, 699],
            2: [1, 644],
            3: [1, 744],
            4: [1, 705],
        },
    },
    'تقريب التهذيب': {
        bookName: 'تقريب التهذيب',
        bookAuthor: 'ابن حجر العسقلاني',
        bookCopy: 'دار العاصمة - 1412',
        bookPath: 'narrators_commentary/taqreebtahzeeb',
        totalVolume: '1',
        volumesPage: {
            1: [1, 1412],
        },
    },
    'تهذيب الكمال في أسماء الرجال': {
        bookName: 'تهذيب الكمال في أسماء الرجال',
        bookAuthor: 'ابن حجر العسقلاني',
        bookCopy: 'مؤسسة الرسالة - بيروت - 1400 - 1980',
        bookPath: 'narrators_commentary/tahzeebkamal',
        totalVolume: '35',
        volumesPage: {
            1: [1, 527],
            2: [1, 537],
            3: [1, 504],
            4: [1, 570],
            5: [1, 608],
            6: [1, 573],
            7: [1, 506],
            8: [1, 544],
            9: [1, 547],
            10: [1, 561],
            11: [1, 467],
            12: [1, 623],
            13: [1, 560],
            14: [1, 571],
            15: [1, 524],
            16: [1, 572],
            17: [1, 496],
            18: [1, 556],
            19: [1, 571],
            20: [1, 530],
            21: [1, 620],
            22: [1, 650],
            23: [1, 643],
            24: [1, 598],
            25: [1, 678],
            26: [1, 663],
            27: [1, 602],
            28: [1, 614],
            29: [1, 513],
            30: [1, 497],
            31: [1, 584],
            32: [1, 575],
            33: [1, 479],
            34: [1, 533],
            35: [1, 449],
        },
    },
    'الجرح والتعديل لابن أبي حاتم': {
        bookName: 'الجرح والتعديل لابن أبي حاتم',
        bookAuthor: 'عبد الرحمن بن أبي حاتم',
        bookCopy: 'دار إحياء التراث العربي - 1271 هـ 1952 م',
        bookPath: 'narrators_commentary/jarh_ibnabihatem',
        totalVolume: '9',
        volumesPage: {
            1: [1, 421],
            2: [1, 569],
            3: [1, 644],
            4: [1, 516],
            5: [1, 420],
            6: [1, 425],
            7: [1, 341],
            8: [1, 527],
            9: [1, 479],
        },
    },
    'الكامل في ضعفاء الرجال': {
        bookName: 'الكامل في ضعفاء الرجال',
        bookAuthor: 'ابن عدي الجرجاني',
        bookCopy: 'الكتب العلمية - 1418هـ 1997م',
        bookPath: 'narrators_commentary/kamel',
        totalVolume: '9',
        volumesPage: {
            1: [1, 565],
            2: [1, 543],
            3: [1, 590],
            4: [1, 552],
            5: [1, 564],
            6: [1, 551],
            7: [1, 576],
            8: [1, 542],
            9: [1, 476],
        },
    },
    'النهاية في غريب الحديث': {
        bookName: 'النهاية في غريب الحديث',
        bookAuthor: 'ابن الأثير',
        bookCopy: 'المكتبة العلمية - بيروت 1399هـ - 1979م',
        bookPath: 'ambiguous/ibnatheer',
        totalVolume: '5',
        volumesPage: {
            1: [1, 496],
            2: [1, 526],
            3: [1, 488],
            4: [1, 384],
            5: [1, 498],
        },
    },
    // 'لسان العرب': {
    //     bookName: 'لسان العرب',
    //     bookAuthor: 'ابن منظور',
    //     bookCopy: 'دار صادر - بيروت - 1414',
    //     bookPath: 'ambiguous/lisanarab',
    //     totalVolume: '15',
    //     volumesPage: {
    //         1: [1, 809],
    //         2: [1, 646],
    //         3: [1, 524],
    //         4: [1, 628],
    //         5: [1, 440],
    //         6: [1, 374],
    //         7: [1, 472],
    //         8: [1, 462],
    //         9: [1, 366],
    //         10: [1, 520],
    //         11: [1, 744],
    //         12: [1, 656],
    //         13: [1, 568],
    //         14: [1, 492],
    //         15: [1, 496],
    //     },
    // },
    'فتح الباري بشرح صحيح البخاري': {
        bookName: 'فتح الباري بشرح صحيح البخاري',
        bookAuthor: 'ابن حجر العسقلاني',
        bookCopy: 'دارالريان للتراث - 1407هـ / 1986م',
        bookPath: 'explanation/fathbaryibnhajar',
        totalVolume: '13',
        volumesPage: {
            1: [1, 716],
            2: [1, 695],
            3: [1, 742],
            4: [1, 584],
            5: [1, 492],
            6: [1, 744],
            7: [1, 768],
            8: [1, 732],
            9: [1, 600],
            10: [1, 637],
            11: [1, 632],
            12: [1, 472],
            13: [1, 580],
        },
    },
    'عمدة القاري شرح صحيح البخاري': {
        bookName: 'عمدة القاري شرح صحيح البخاري',
        bookAuthor: 'بدر الدين العيني',
        bookCopy: 'دار إحياء التراث العربي',
        bookPath: 'explanation/umdetqary',
        totalVolume: '25',
        volumesPage: {
            1: [1,332],
            2: [1,314],
            3: [1,328],
            4: [1,318],
            5: [1,320],
            6: [1,320],
            7: [1,328],
            8: [1,318],
            9: [1,312],
            10: [1,312],
            11: [1,312],
            12: [1,304],
            13: [1,312],
            14: [1,320],
            15: [1,320],
            16: [1,320],
            17: [1,320],
            18: [1,320],
            19: [1,320],
            20: [1,320],
            21: [1,320],
            22: [1,320],
            23: [1,304],
            24: [1,288],
            25: [1,208],
        },
    },
    'فتح الباري في شرح صحيح البخاري': {
        bookName: 'فتح الباري في شرح صحيح البخاري',
        bookAuthor: 'ابن رجب الحنبلي',
        bookCopy: 'دار ابن الجوزي',
        bookPath: 'explanation/fathbaryibnrajab',
        totalVolume: '7',
        volumesPage: {
            1: [1,558],
            2: [1,742],
            3: [1,616],
            4: [1,510],
            5: [1,556],
            6: [1,542],
            7: [1,748],
        },
    },
    'التوضيح لشرح الجامع الصحيح': {
        bookName: 'التوضيح لشرح الجامع الصحيح',
        bookAuthor: 'ابن الملقن',
        bookCopy: 'وزارة الأوقاف والشؤون الإسلامية قطر - ٢٠٠٩',
        bookPath: 'explanation/tawdih',
        totalVolume: '36',
        volumesPage: {
            1: [1,443],
            2: [1,672],
            3: [1,688],
            4: [1,678],
            5: [1,644],
            6: [1,654],
            7: [1,658],
            8: [1,553],
            9: [1,645],
            10: [1,660],
            11: [1,605],
            12: [1,600],
            13: [1,681],
            14: [1,656],
            15: [1,674],
            16: [1,689],
            17: [1,678],
            18: [1,677],
            19: [1,668],
            20: [1,596],
            21: [1,655],
            22: [1,638],
            23: [1,629],
            24: [1,630],
            25: [1,611],
            26: [1,685],
            27: [1,699],
            28: [1,678],
            29: [1,642],
            30: [1,607],
            31: [1,597],
            32: [1,673],
            33: [1,614],
            34: [1,629],
            35: [1,648],
            36: [1,565],
        }
    },
    'المفهم لما أشكل من تلخيص كتاب مسلم': {
        bookName: 'المفهم لما أشكل من تلخيص كتاب مسلم',
        bookAuthor: 'أبو العباس القرطبي',
        bookCopy: 'دار ابن كثير',
        bookPath: 'explanation/mofhim',
        totalVolume: '7',
        volumesPage: {
            1: [1,626],
            2: [1,652],
            3: [1,776],
            4: [1,653],
            5: [1,648],
            6: [1,720],
            7: [1,854],
        },
    },
    'السراج الوهاج من كشف مطالب صحيح مسلم بن الحجاج': {
        bookName: 'السراج الوهاج من كشف مطالب صحيح مسلم بن الحجاج',
        bookAuthor: 'أبي الطيب صديق',
        bookCopy: 'وزارة الأوقاف والشؤون الإسلامية القطرية',
        bookPath: 'explanation/SirajWahaj',
        totalVolume: '11',
        volumesPage: {
            1: [1,538],
            2: [1,551],
            3: [1,661],
            4: [1,638],
            5: [1,653],
            6: [1,667],
            7: [1,654],
            8: [1,573],
            9: [1,695],
            10: [1,678],
            11: [1,855],
        },
    },
    'تحفة الأحوذي بشرح جامع الترمذي': {
        bookName: 'تحفة الأحوذي بشرح جامع الترمذي',
        bookAuthor: 'عبد الرحمن المباركفوري',
        bookCopy: 'دار الكتب العلمية',
        bookPath: 'explanation/ahouzy',
        totalVolume: '10',
        volumesPage: {
            1: [1, 544],
            2: [1, 516],
            3: [1, 594],
            4: [1, 608],
            5: [1, 520],
            6: [1, 528],
            7: [1, 442],
            8: [1, 492],
            9: [1, 386],
            10: [1, 398],
        },
    },
    'شرح السيوطي لسنن النسائي': {
        bookName: 'شرح السيوطي لسنن النسائي',
        bookAuthor: 'جلال الدين السيوطي',
        bookCopy: 'مكتب المطبوعات الإسلامية - 1414هـ / 1994م',
        bookPath: 'hadith/319',
        totalVolume: '9',
        volumesPage: {
            1: [1, 309],
            2: [1, 255],
            3: [1, 277],
            4: [1, 232],
            5: [1, 286],
            6: [1, 288],
            7: [1, 332],
            8: [1, 348],
            9: [1, 367],
        },
    },
    'شرح الزرقاني على موطأ الإمام مالك': {
        bookName: 'شرح الزرقاني على موطأ الإمام مالك',
        bookAuthor: 'محمد بن عبد الباقي الزرقاني',
        bookCopy: 'مكتبة الثقافة الدينية',
        bookPath: 'explanation/zarqany',
        totalVolume: '4',
        volumesPage: {
            1: [1, 572],
            2: [1, 540],
            3: [1, 488],
            4: [1, 608],
        },
    },
    'التمهيد لما في الموطأ من المعاني والأسانيد': {
        bookName: 'التمهيد لما في الموطأ من المعاني والأسانيد',
        bookAuthor: 'ابن عبد البر',
        bookCopy: 'مكتبة الثقافة الدينية',
        bookPath: 'explanation/tamheed',
        totalVolume: '24',
        volumesPage: {
            1: [1,456],
            2: [1,380],
            3: [1,414],
            4: [1,421],
            5: [1,448],
            6: [1,543],
            7: [1,298],
            8: [1,444],
            9: [1,333],
            10: [1,364],
            11: [1,276],
            12: [1,351],
            13: [1,394],
            14: [1,468],
            15: [1,387],
            16: [1,419],
            17: [1,516],
            18: [1,403],
            19: [1,369],
            20: [1,320],
            21: [1,345],
            22: [1,400],
            23: [1,507],
            24: [1,508],
        }
    },
    'الاستذكار الجامع لمذاهب فقهاء الأمصار وعلماء الأقطار': {
        bookName: 'الاستذكار الجامع لمذاهب فقهاء الأمصار وعلماء الأقطار',
        bookAuthor: 'ابن عبد البر',
        bookCopy: 'دار قتيبة',
        bookPath: 'explanation/estzkar',
        totalVolume: '30',
        volumesPage: {
            1: [1,415],
            2: [1,310],
            3: [1,291],
            4: [1,433],
            5: [1,473],
            6: [1,391],
            7: [1,285],
            8: [1,439],
            9: [1,389],
            10: [1,359],
            11: [1,324],
            12: [1,342],
            13: [1,396],
            14: [1,368],
            15: [1,536],
            16: [1,404],
            17: [1,334],
            18: [1,312],
            19: [1,286],
            20: [1,296],
            21: [1,342],
            22: [1,382],
            23: [1,416],
            24: [1,336],
            25: [1,352],
            26: [1,384],
            27: [1,464],
            28: [1,345],
            29: [1,282],
            30: [1,272],
        }
    },
    'حاشية السندي على ابن ماجه': {
        bookName: 'حاشية السندي على ابن ماجه',
        bookAuthor: 'محمد بن عبدالهادي السندي',
        bookCopy: 'مكتبة الثقافة الدينية',
        bookPath: 'explanation/sindyibnmajah',
        totalVolume: '2',
        volumesPage: {
            1: [1, 660],
            2: [1, 598],
        },
    },
    'تهذيب سنن أبي داود': {
        bookName: 'تهذيب سنن أبي داود',
        bookAuthor: 'ابن القيم الجوزية',
        bookCopy: 'طبعة عطاءات العلم',
        bookPath: 'explanation/tahzeeb_abudaoud',
        totalVolume: '3',
        volumesPage: {
            1: [1,597],
            2: [1,652],
            3: [1,673],
        }
    },
    'حاشية السندي على المسند': {
        bookName: 'حاشية السندي على المسند',
        bookAuthor: 'محمد بن عبدالهادي السندي',
        bookCopy: 'وزارة الأوقاف والشؤون الإسلامية قطر',
        bookPath: 'explanation/sindy_ahmad',
        totalVolume: '17',
        volumesPage: {
            1: [1,454],
            2: [1,510],
            3: [1,509],
            4: [1,509],
            5: [1,509],
            6: [1,509],
            7: [1,509],
            8: [1,515],
            9: [1,518],
            10: [1,517],
            11: [1,516],
            12: [1,513],
            13: [1,512],
            14: [1,514],
            15: [1,510],
            16: [1,512],
            17: [1,512],
        }
    },
    'المنهاج شرح صحيح مسلم بن الحجاج': {
        bookName: 'المنهاج شرح صحيح مسلم بن الحجاج',
        bookAuthor: ' يحيى بن شرف النووي',
        bookCopy: 'دار الخير - 1416هـ / 1996م',
        bookPath: 'explanation/minhaj',
        totalVolume: '18',
        volumesPage: {
            1: [1, 336],
            2: [1, 312],
            3: [1, 304],
            4: [1, 320],
            5: [1, 333],
            6: [1, 342],
            7: [1, 343],
            8: [1, 329],
            9: [1, 340],
            10: [1, 352],
            11: [1, 328],
            12: [1, 346],
            13: [1, 334],
            14: [1, 354],
            15: [1, 324],
            16: [1, 352],
            17: [1, 310],
            18: [1, 224],
        },
    },
    'صحيح سنن الترمذي': {
        bookName: 'صحيح سنن الترمذي',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤٢٠ هـ - ٢٠٠٠ م',
        bookPath: 'ruling/albani_tirmizi_saheeh',
        totalVolume: '3',
        volumesPage: {
            1: [1, 655],
            2: [1, 651],
            3: [1, 622],
        },
    },
    'ضعيف سنن الترمذي': {
        bookName: 'ضعيف سنن الترمذي',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤٢٠ هـ - ٢٠٠٠ م',
        bookPath: 'ruling/albani_tirmizi_daeef',
        totalVolume: '1',
        volumesPage: {
            1: [1, 756],
        },
    },
    'صحيح سنن ابن ماجه': {
        bookName: 'صحيح سنن ابن ماجه',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٧ هـ - ١٩٩٧ م',
        bookPath: 'ruling/albani_ibnmajah_saheeh',
        totalVolume: '3',
        volumesPage: {
            1: [1, 448],
            2: [1, 444],
            3: [1, 600],
        },
    },
    'ضعيف سنن ابن ماجه': {
        bookName: 'ضعيف سنن ابن ماجه',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٧ هـ - ١٩٩٧ م',
        bookPath: 'ruling/albani_ibnmajah_daeef',
        totalVolume: '1',
        volumesPage: {
            1: [1, 440],
        },
    },
    'صحيح سنن النسائي': {
        bookName: 'صحيح سنن النسائي',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٩ هـ - ١٩٩٨ م',
        bookPath: 'ruling/albani_nasai_saheeh',
        totalVolume: '3',
        volumesPage: {
            1: [1, 616],
            2: [1, 620],
            3: [1, 563],
        },
    },
    'ضعيف سنن النسائي': {
        bookName: 'ضعيف سنن النسائي',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٩ هـ - ١٩٩٨ م',
        bookPath: 'ruling/albani_nasai_daeef',
        totalVolume: '1',
        volumesPage: {
            1: [1, 479],
        },
    },
    'صحيح سنن أبي داود': {
        bookName: 'صحيح سنن أبي داود',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٩ هـ - ١٩٩٨ م',
        bookPath: 'ruling/albani_abudaoud_saheeh',
        totalVolume: '3',
        volumesPage: {
            1: [1, 639],
            2: [1, 593],
            3: [1, 311],
        },
    },
    'ضعيف سنن أبي داود': {
        bookName: 'ضعيف سنن أبي داود',
        bookAuthor: 'ناصر الدين الألباني',
        bookCopy: 'مكتبة المعارف ١٤١٩ هـ - ١٩٩٨ م',
        bookPath: 'ruling/albani_abudaoud_daeef',
        totalVolume: '1',
        volumesPage: {
            1: [1, 468],
        },
    },
    'سنن أبي داود تخريج شعيب الأرنؤوط': {
        bookName: 'سنن أبي داود تخريج شعيب الأرنؤوط',
        bookAuthor: 'شعيب الأرنؤوط',
        bookCopy: 'دار الرسالة العالمية - الطبعة الأولى ٢٠٠٩ - ١٤٣٠',
        bookPath: 'ruling/abudaoud_shuaib',
        totalVolume: '7',
        volumesPage: {
            1: [1, 505],
            2: [1, 672],
            3: [1, 632],
            4: [1, 716],
            5: [1, 678],
            6: [1, 661],
            7: [1, 876],
        },
    },
    'مسند أحمد تخريج شعيب الأرنؤوط': {
        bookName: 'مسند أحمد تخريج شعيب الأرنؤوط',
        bookAuthor: 'شعيب الأرنؤوط',
        bookCopy: 'دار الرسالة العالمية - الطبعة الأولى ٢٠٠١ - ١٤٢١',
        bookPath: 'ruling/ahmad_shuaib',
        totalVolume: '50',
        volumesPage: {
            1: [1, 575],
            2: [1, 487],
            3: [1, 526],
            4: [1, 516],
            5: [1, 496],
            6: [1, 433],
            7: [1, 462],
            8: [1, 528],
            9: [1, 525],
            10: [1, 538],
            11: [1, 711],
            12: [1, 520],
            13: [1, 548],
            14: [1, 559],
            15: [1, 554],
            16: [1, 646],
            17: [1, 491],
            18: [1, 446],
            19: [1, 500],
            20: [1, 467],
            21: [1, 512],
            22: [1, 471],
            23: [1, 453],
            24: [1, 511],
            25: [1, 517],
            26: [1, 404],
            27: [1, 386],
            28: [1, 659],
            29: [1, 650],
            30: [1, 638],
            31: [1, 575],
            32: [1, 568],
            33: [1, 476],
            34: [1, 595],
            35: [1, 525],
            36: [1, 661],
            37: [1, 547],
            38: [1, 568],
            39: [1, 589],
            40: [1, 515],
            41: [1, 525],
            42: [1, 523],
            43: [1, 458],
            44: [1, 636],
            45: [1, 633],
            46: [1, 472],
            47: [1, 492],
            48: [1, 502],
            49: [1, 464],
            50: [1, 559],
        },
    },
    'البدر المنير في تخريج الأحاديث والآثار الواقعة في الشرح الكبير': {
        bookName: 'البدر المنير في تخريج الأحاديث والآثار الواقعة في الشرح الكبير',
        bookAuthor: 'ابن الملقن',
        bookCopy: 'دار الهجرة - ١٤٢٥هـ-٢٠٠٤م',
        bookPath: 'ruling/badrmuneer',
        totalVolume: '10',
        volumesPage: {
            1: [1,735],
            2: [1,682],
            3: [1,682],
            4: [1,696],
            5: [1,779],
            6: [1,778],
            7: [1,694],
            8: [1,754],
            9: [1,764],
            10: [1,635],
        }
    },
    'التلخيص الحبير في تخريج أحاديث الرافعي الكبير': {
        bookName: 'التلخيص الحبير في تخريج أحاديث الرافعي الكبير',
        bookAuthor: 'ابن حجر العسقلاني',
        bookCopy: 'مؤسسة قرطبة - 1416هـ',
        bookPath: 'ruling/habeer',
        totalVolume: '4',
        volumesPage: {
            1: [1,523],
            2: [1,560],
            3: [1,488],
            4: [1,572],
        }
    },
    'علل الترمذي الكبير': {
        bookName: 'علل الترمذي الكبير',
        bookAuthor: 'محمد بن عيسى الترمذي',
        bookCopy: 'عالم الكتب , مكتبة النهضة العربية 1409',
        bookPath: 'commentary/ilaltirmizi',
        totalVolume: '1',
        volumesPage: {
            1: [1, 478],
        },
    },
    // 'كتاب العلل لابن المديني': {
    //     bookName: 'كتاب العلل لابن المديني',
    //     bookAuthor: 'علي بن المديني',
    //     bookCopy: 'المكتب الإسلامي 1980',
    //     bookPath: 'commentary/ilalibnmadini',
    //     totalVolume: '1',
    //     volumesPage: {
    //         1: [1,127],
    //     },
    // },
    'الإلزامات والتتبع للدارقطني': {
        bookName: 'الإلزامات والتتبع للدارقطني',
        bookAuthor: 'أبو الحسن الدارقطني',
        bookCopy: 'المكتب الإسلامي 1980',
        bookPath: 'commentary/ilzamat',
        totalVolume: '1',
        volumesPage: {
            1: [1, 415],
        },
    },
    // 'بيان الوهم والإيهام في كتاب الأحكام': {
    //     bookName: 'بيان الوهم والإيهام في كتاب الأحكام',
    //     bookAuthor: 'أبو الحسن ابن القطان',
    //     bookCopy: 'دار طيبة - الرياض الطبعة : الأولى ، 1418هـ-1997م',
    //     bookPath: 'commentary/wahmiham',
    //     totalVolume: '6',
    //     volumesPage: {
    //         1: [1,513],
    //         2: [1,624],
    //         3: [1,624],
    //         4: [1,681],
    //         5: [1,838],
    //         6: [1,585],
    //     }
    // },

    // 'العلل المتناهية في الأحاديث الواهية': {
    //     bookName: 'العلل المتناهية في الأحاديث الواهية	',
    //     bookAuthor: 'ابن الجوزي',
    //     bookCopy: 'إدارة العلوم الأثرية',
    //     bookPath: 'commentary/mutanahya',
    //     totalVolume: '1',
    //     volumesPage: {
    //         1: [1,968]
    //     }
    // },
    // 'المنتخب من العلل للخلال': {
    //     bookName: 'المنتخب من العلل للخلال',
    //     bookAuthor: ' ابن الجوزي',
    //     bookCopy: 'دار الراية للنشر والتوزيع الطبعة: الثانية، 1401هـ/1981م',
    //     bookPath: 'commentary/muntakhab',
    //     totalVolume: '1',
    //     volumesPage: {
    //       1: [1,370]
    //     }
    // },
};
