import { ReactElement, type ReactNode, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';
import CopyHadithModal from '@components/modal/Modal/CopyHadithModal';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import { env } from '@constants/env';
import { mainSearchStateKeys } from '@constants/filters';
import { useContextualRouting } from '@hooks';
import { type ErrorData, useCommonContext } from '@hooks/commonContext';
import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import {
    Box,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import displayMatnComparison from '@utils/displayTakhreejComparison';
import { NarratorColor } from '@utils/narratorColor';
import { removeTashkeel } from '@utils/tashkeel';

import BareBonesHadith from '../BareBonesHadith';
import { getBookAndIdText, htmlToReactParserOptions } from '../helpers';
import {
    HadithActions,
    IHadithLeftAction,
    IHadithRightAction,
} from './HadithActions';
import { HadithContainer, HadithMatnComparisonContainer } from './styled';
import { HadithViews, NarratorViews, RoadsViews } from 'constants/views';
import { Parser } from 'html-to-react';
import { analytics } from 'lib/analytics';
import type {
    BookReference,
    GenericHadith,
    NarratorHadith,
    RoadsHadith,
    RulingsHadith,
    SearchPageHadith,
} from 'shared/interfaces/hadith';

interface HadithProps {
    hadith_object: GenericHadith;
    hadithToCompare?: GenericHadith;
    withMatnComparison?: boolean;
    rightActions?: IHadithRightAction[] | null;
    allowNarratorClick?: boolean;
    narratorsColors?: NarratorColor[];
    isSelectedRoad?: boolean;
    setShowSnackbar?: (props: MySnackbarProps) => void;
    turnOffLeftActions?: boolean;
    bookNameRender?: ReactNode;
    quickNavPoints?: BookReference[];
    collapsable?: boolean;
    noCopy?: boolean;
    noBookPreview?: boolean;
    routing?: ROUTING;
    reportErrorId?: string;
    reportErrorType?: ErrorData['type'];
}

const HadithResultItem = ({
    hadith_object,
    hadithToCompare,
    rightActions = null,
    withMatnComparison = false,
    allowNarratorClick = true,
    narratorsColors = [],
    isSelectedRoad = false,
    setShowSnackbar,
    turnOffLeftActions = false,
    bookNameRender,
    quickNavPoints = [],
    collapsable = false,
    noCopy = false,
    noBookPreview = false,
    routing,
    reportErrorId,
    reportErrorType,
}: HadithProps) => {
    const { t } = useTranslation('library');
    const theme = useTheme();
    const router = useRouter();
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();

    const narratorViewKey =
        routing === ROUTING.NARRATOR_PAGE
            ? 'narratorView'
            : 'hadithNarratorView';

    const narratorIdKey =
        routing === ROUTING.NARRATOR_PAGE ? 'narratorId' : 'hadithNarratorId';

    // relevant when using the component in the /narrators page
    // so we don't overwrite these
    const { [narratorIdKey]: narratorId, [narratorViewKey]: narratorView } =
        router.query;

    const matnComparison: ReactElement | null = useMemo(() => {
        if (
            withMatnComparison &&
            'matn_with_tashkeel' in hadith_object &&
            hadithToCompare &&
            'matn_with_tashkeel' in hadithToCompare
        ) {
            const htmlToReactParser = Parser();
            return htmlToReactParser.parse(
                tashkeelEnabled
                    ? displayMatnComparison(
                          hadithToCompare.matn_with_tashkeel,
                          hadith_object.matn_with_tashkeel,
                          fontSize.body,
                      )
                    : removeTashkeel(
                          displayMatnComparison(
                              hadithToCompare.matn_with_tashkeel,
                              hadith_object.matn_with_tashkeel,
                              fontSize.body,
                          ),
                      ),
            ) as ReactElement;
        } else {
            return null;
        }
    }, [fontSize, tashkeelEnabled]);

    const [copyModalOpen, setCopyModalOpen] = useState(false);
    const { makeContextualHref } = useContextualRouting({
        queryToKeep: mainSearchStateKeys,
    });

    const { setErrorData } = useCommonContext();

    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [collapsed, setCollapsed] = useState(collapsable);

    const {
        book_name,
        chapter,
        number,
        type,
        hadith_id,
        narrators,
        hadith,
        volume,
        page,
        sub_chapter,
        editions,
    } = hadith_object;

    // DO NOT RENDER IF HADITH IS MISSING
    if (!hadith) {
        return null;
    }

    // set a dummy list of quick nav points consisting of a single nav point
    quickNavPoints =
        quickNavPoints.length > 0
            ? quickNavPoints
            : [
                  {
                      page: page ?? 0,
                      volume: volume ?? 0,
                      book_name,
                      editionsRefs: editions,
                      id: hadith_id,
                      label: `${book_name} - ${number.join(',')}`,
                      group: book_name,
                  },
              ];

    const onNarratorClick = allowNarratorClick
        ? (narratorId: string, narratorView: NarratorViews | undefined) => {
              router.push(
                  makeContextualHref({
                      hadithId: hadith_id,
                      hadithView: HadithViews.NARRATOR,
                      // this is always routing using `hadithNarratorId` and `hadithNarratorView`
                      // i.e., the pieces of state within the modal, because clicking on the narrator
                      // in the Hadith will always open / navigate the modal, not the narrators page
                      // so we don't use 'narratorId' or 'narratorView', only the 'hadith' versions of these.
                      hadithNarratorId: narratorId,
                      hadithNarratorView: narratorView ?? NarratorViews.BIO,
                  }),
                  undefined,
                  { shallow: true },
              );
          }
        : undefined;

    const onAmbiguousClick = (ambiguousId: number) => {
        router.push(
            makeContextualHref({
                hadithId: hadith_id,
                hadithView: HadithViews.AMBIGUOUS_WORDS,
                ambg_id: ambiguousId,
            }),
            undefined,
            { shallow: true },
        );
    };

    const firstAmbiguousId =
        'ambiguous' in hadith_object
            ? hadith_object.ambiguous[0]?.reference_id ?? ''
            : '';

    const hadithOptions = htmlToReactParserOptions(
        narrators,
        narratorsColors,
        router,
        onNarratorClick,
        onAmbiguousClick,
        routing,
        (router.query['ambg_id'] as string) || firstAmbiguousId,
        fontSize.body,
    );

    // open the copy modal for hadith
    // straight up copy for explanations, commenatries etc
    const copyAction = () => {
        analytics('copy', { id: hadith_id, source: 'hadith-result-item' });
        setCopyModalOpen(true);
    };

    let leftActions: IHadithLeftAction[] = [];

    leftActions = turnOffLeftActions
        ? []
        : [
              {
                  icon: PreviewIcon,
                  action: () => {
                      analytics('book-preview', {
                          type: 'hadith',
                          id: hadith_id,
                          source: 'hadith-result-item',
                      });
                      setOpenPreviewModal(true);
                  },
                  isClickable: !!(booksConfig[book_name] && page && volume),
                  notClickableMessage: t('preview_not_clickable'),
              },
              {
                  icon: ContentCopyIcon,
                  action: copyAction,
                  isClickable: true,
              },
              {
                  icon: ReportGmailerrorred,
                  action: () => {
                      setErrorData({
                          elem_id: reportErrorId ?? hadith_id,
                          type: reportErrorType ?? 'hadith',
                          source: 'user-report',
                      });
                  },
                  isClickable: true,
              },
          ];

    if (noCopy) {
        leftActions = leftActions.filter(
            (action) => action.icon !== ContentCopyIcon,
        );
    }

    if (noBookPreview) {
        leftActions = leftActions.filter(
            (action) => action.icon !== PreviewIcon,
        );
    }

    //TODO [@chammaaomar]: Remove. temporary; for validation puposes
    const score =
        env !== 'prod' &&
        !isMobile &&
        'tag' in hadith_object &&
        hadith_object.tag === 'semantic' &&
        '_score' in hadith_object
            ? `${t('score')}: ${hadith_object._score}`
            : '';

    //TODO [@chammaaomar]: Remove. temporary; for validation puposes
    const hybridSource =
        'tag' in hadith_object && hadith_object.tag === 'semantic'
            ? 'matched_queries' in hadith_object &&
              hadith_object.matched_queries?.length
                ? 'textual'
                : 'semantic'
            : '';

    //TODO [@chammaaomar]: Remove. temporary; for validation puposes
    const hybridSourceDetailsBody =
        env !== 'prod' && !isMobile && hybridSource
            ? `${t('source')}: ${t(hybridSource)}`
            : '';

    return (
        <>
            <HadithContainer
                elevation={0}
                variant="outlined"
                sx={{
                    margin: { xs: '10px 0', md: '10px 0' },
                    width: '100%',
                    background: isSelectedRoad ? '#FFF4E5' : 'transparent',
                }}
            >
                <BareBonesHadith
                    hadithHtml={hadith}
                    book={bookNameRender ?? book_name}
                    bookNameRenderFlag={bookNameRender ? true : false}
                    chapter={chapter}
                    subChapter={sub_chapter ?? ''}
                    type={type}
                    page={page ?? undefined}
                    volume={volume ?? undefined}
                    titleDivider
                    hadith_numbers={number}
                    hadithOptions={hadithOptions}
                    collapsable={collapsable}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    hybridSource={hybridSourceDetailsBody}
                    score={score}
                />

                {booksConfig[book_name] &&
                    volume &&
                    page &&
                    openPreviewModal && (
                        <BookPreview
                            initNavPointId={hadith_id}
                            initEditionsRefs={editions}
                            open={openPreviewModal}
                            setOpen={setOpenPreviewModal}
                            quickNavPoints={quickNavPoints}
                        />
                    )}

                {matnComparison && hadithToCompare && (
                    <>
                        <Divider />
                        <Typography
                            variant="subtitle1"
                            fontSize={18}
                            paddingLeft={1}
                            fontWeight="500"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingTop: 1,
                            }}
                        >
                            <Box fontSize={fontSize.subtitle}>
                                {t('matn_comparison')}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: 1,
                                    paddingRight: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        backgroundColor: '#ffe6e6',
                                        textDecoration: 'line-through',
                                        padding: '7px 5px',
                                        borderRadius: '10px',
                                        fontSize: fontSize.body,
                                    }}
                                >
                                    {t('matn_removal')}
                                </Typography>
                                <Typography
                                    sx={{
                                        backgroundColor: '#e6ffe6',
                                        textDecoration: 'underline',
                                        padding: '7px 5px',
                                        fontSize: fontSize.body,
                                        borderRadius: '10px',
                                    }}
                                >
                                    {t('matn_addition')}
                                </Typography>
                            </Box>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            textAlign="justify"
                            // fontSize={isMobile ? 14 : 16}
                            fontSize={fontSize.subtitle}
                            paddingLeft={1}
                        >
                            {getBookAndIdText(
                                hadithToCompare.book_name,
                                hadithToCompare.number[0],
                            )}
                            <IconButton disabled>
                                <CompareArrowsIcon color="primary" />
                            </IconButton>
                            {getBookAndIdText(book_name, number[0])}
                        </Typography>
                        <HadithMatnComparisonContainer>
                            {matnComparison}
                        </HadithMatnComparisonContainer>
                    </>
                )}

                <HadithActions
                    rightActions={
                        rightActions || [
                            {
                                text: t('roadsWithoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.ROADS,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView: HadithViews.ROADS,
                                            roadsView: RoadsViews.ROADS,
                                        }),
                                        undefined,
                                        {
                                            shallow: true,
                                        },
                                    );
                                },
                                // the hadith id itself is always appended to the raw_narrations inside so it's never empty
                                // TODO [@chammaaomar]: do it at the zod level, when parsing the data from elastic
                                isClickable: 'raw_narrations' in hadith_object,
                                notClickableMessage: t('roads_not_clickable'),
                            },
                            {
                                text: t('narratorsWithoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.NARRATOR,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView: HadithViews.NARRATOR,
                                            // NOTE: showing details of first narrator by default
                                            hadithNarratorId:
                                                (narratorId as string) ||
                                                narrators?.[0].id,
                                            hadithNarratorView:
                                                (narratorView as string) ||
                                                NarratorViews.BIO,
                                        }),
                                        undefined,
                                        { shallow: true },
                                    );
                                },
                                isClickable: narrators.length > 0,
                                notClickableMessage: t(
                                    'narrators_not_clickable',
                                ),
                            },
                            {
                                text: t('explanationWithoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.EXPLANATION,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView: HadithViews.EXPLANATION,
                                        }),
                                        undefined,
                                        { shallow: true },
                                    );
                                },
                                isClickable:
                                    'hasExplanation' in hadith_object &&
                                    hadith_object.hasExplanation,
                                notClickableMessage: t(
                                    'explanation_not_clickable',
                                ),
                            },
                            {
                                text: t('commentaryWithoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.COMMENTARY,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView: HadithViews.COMMENTARY,
                                        }),
                                        undefined,
                                        { shallow: true },
                                    );
                                },
                                isClickable:
                                    'hasCommentary' in hadith_object &&
                                    hadith_object.hasCommentary,
                                notClickableMessage: t(
                                    'commentary_not_clickable',
                                ),
                            },
                            {
                                text: t('rulingsWithoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.RULINGS,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView: HadithViews.RULINGS,
                                        }),
                                        undefined,
                                        { shallow: true },
                                    );
                                },
                                isClickable:
                                    'hasRuling' in hadith_object &&
                                    hadith_object.hasRuling,
                                notClickableMessage: t('ruling_not_clickable'),
                            },
                            {
                                text: t('ambiguous_words_withoutAl'),
                                action: () => {
                                    analytics('hadith', {
                                        hadith_id,
                                        view: HadithViews.AMBIGUOUS_WORDS,
                                        source: 'hadith-result-item',
                                    });
                                    router.push(
                                        makeContextualHref({
                                            hadithId: hadith_id,
                                            hadithView:
                                                HadithViews.AMBIGUOUS_WORDS,
                                        }),
                                        undefined,
                                        { shallow: true },
                                    );
                                },
                                isClickable:
                                    'ambiguous' in hadith_object &&
                                    hadith_object.ambiguous.length > 0,
                                notClickableMessage: t(
                                    'ambiguous_not_clickable',
                                ),
                            },
                        ]
                    }
                    leftActions={leftActions}
                />
            </HadithContainer>
            {copyModalOpen && (
                <CopyHadithModal
                    onClose={() => setCopyModalOpen(false)}
                    hadithHtml={hadith}
                    // not great, since the type is technically not correct
                    hadith={
                        hadith_object as
                            | SearchPageHadith
                            | RoadsHadith
                            | NarratorHadith
                            | RulingsHadith
                    }
                    setShowSnackbar={setShowSnackbar}
                    title={t('copy_config')}
                    downloadText={t('copy_hadith')}
                />
            )}
        </>
    );
};

export default HadithResultItem;
