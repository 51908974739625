import { SearchType } from 'shared/interfaces/hadith';

export enum HadithViewFilters {
    HADITH_ID = 'hadith_id',
}

export const DEFAULT_SEARCH_TYPE: SearchType = 'token';

export const numNeighbors = 10;

// This DOESN'T correspond to a frontend reactivesearch component, instead, it corresponds to a
// request that we add on the server-side to the batch request going to _msearch when the user
// performs a semantic search. This RESULT_TEXTUAL request performs the match_phrase and match_token
// queries necessary to make semantic search HYBRID, i.e., involving both semantic and textual search
// look at the reactivesearchproxy for its usage
export enum ServerSearchFilters {
    RESULT_TEXTUAL = 'server-result-textual',
}

export enum SearchFilters {
    RESULT = 'result',
    SEARCH = 'search',
    SEARCH_TYPE = 'search_type',
    NARRATORS_LINKED = 'narrators_linked',
    HADITH_NUMBER_SEARCH = 'hadith_number_search',
    BOOKS_FILTERS = 'books_filters',
    NARRATORS_FILTERS = 'narrators_filters',
    NARRATORS_SEARCH = 'narrators_search',
    CHAPTERS_FILTERS = 'chapters_filters',
    CHAPTERS_SEARCH = 'chapters_filters_search',
    SUB_CHAPTERS_FILTERS = 'sub_chapters_filters',
    SUB_CHAPTERS_SEARCH = 'sub_chapters_filters_search',
    ROOT_KEYWORDS_SEARCH = 'root_keywords_filters_search',
    HADITH_TYPES_FILTERS = 'hadith_types_filters',
    RULINGS_SEARCH = 'hadith_rulings_filters_search',
}

export const mainSearchFilterNoSearchKeys = [
    SearchFilters.HADITH_NUMBER_SEARCH,
    SearchFilters.BOOKS_FILTERS,
    SearchFilters.NARRATORS_FILTERS,
    SearchFilters.CHAPTERS_FILTERS,
    SearchFilters.SUB_CHAPTERS_FILTERS,
    SearchFilters.HADITH_TYPES_FILTERS,
];

export enum ReferencesFilters {
    RESULT = 'ref_result',
    CONTENT_SEARCH = 'ref_content-search',
    BOOK_FILTER = 'ref_book_type_filter',
    AUTHOR_FILTER = 'ref_author_filter',
    STATUS_FILTER = 'ref_status_filter',
}

export enum NarratorBioViewFilters {
    RESULT = 'result',
    NARRATOR_ID = 'narrator-id',
}

// for narrator students in hadith modal
export enum HadithNarratorStudentsViewFilters {
    RESULT = 'student_result',
    STUDENTS_SEARCH = 'student_search',
    STUDENTS_FILTERS = 'student_filters',
    BOOKS_FILTERS = 'student_books_filters',
    GRADES_FILTERS = 'student_grades_filters',
    GRADES_SEARCH = 'student_grades_filters_search',
    HADITH_VERB_FILTERS = 'student_hadith_verb_filters',
    TYPES_FILTERS = 'student_hadith_type_filters',
    VERBS_SEARCH = 'student_hadith_verb_search',
    NARRATOR_ID = 'student_narrator-id',
    ORIGINS_FILTERS = 'student_origins_filter',
    SINGULARITY_TOGGLE = 'student_singularity_toggle',
}

// for narrator students in /narrators page
export enum NarratorStudentsViewFilters {
    RESULT = 'narr_student_result',
    STUDENTS_SEARCH = 'narr_student_search',
    STUDENTS_FILTERS = 'narr_student_filters',
    BOOKS_FILTERS = 'narr_student_books_filters',
    GRADES_FILTERS = 'narr_student_grades_filters',
    GRADES_SEARCH = 'narr_student_grades_filters_search',
    HADITH_VERB_FILTERS = 'narr_student_hadith_verb_filters',
    TYPES_FILTERS = 'narr_student_hadith_type_filters',
    VERBS_SEARCH = 'narr_student_hadith_verb_search',
    NARRATOR_ID = 'narr_student_narrator-id',
    ORIGINS_FILTERS = 'narr_student_origins_filter',
    SINGULARITY_TOGGLE = 'narr_student_singularity_toggle',
}

// for narrator scholars in hadith modal
export enum HadithNarratorScholarsViewFilters {
    RESULT = 'scholar_result',
    BOOKS_FILTERS = 'scholar_books_filters',
    SCHOLARS_FILTERS = 'scholar_filters',
    GRADES_FILTERS = 'scholar_grades_filters',
    HADITH_VERB_FILTERS = 'scholar_hadith_verb_filters',
    TYPES_FILTERS = 'scholar_hadith_type_filters',
    SINGULARITY_TOGGLE = 'scholar_singularity_toggle',
    GRADES_SEARCH = 'scholar_grades_filters_search',
    VERBS_SEARCH = 'scholar_hadith_verb_search',
    NARRATOR_ID = 'scholar_narrator-id',
    SCHOLARS_SEARCH = 'scholar_search',
    CHAPTER_FILTERS = 'scholar_chapters_filters',
}

// for narrator scholars in /narrators page
export enum NarratorScholarsViewFilters {
    RESULT = 'narr_scholar_result',
    BOOKS_FILTERS = 'narr_scholar_books_filters',
    SCHOLARS_FILTERS = 'narr_scholar_filters',
    GRADES_FILTERS = 'narr_scholar_grades_filters',
    SINGULARITY_TOGGLE = 'narr_scholar_singularity_toggle',
    HADITH_VERB_FILTERS = 'narr_scholar_hadith_verb_filters',
    TYPES_FILTERS = 'narr_scholar_hadith_type_filters',
    GRADES_SEARCH = 'narr_scholar_grades_filters_search',
    VERBS_SEARCH = 'narr_scholar_hadith_verb_search',
    NARRATOR_ID = 'narr_scholar_narrator-id',
    SCHOLARS_SEARCH = 'narr_scholar_search',
    CHAPTER_FILTERS = 'narr_scholar_chapters_filters',
}

// for narrator commentary in hadith modal
export enum HadithNarratorCommentaryViewFilters {
    RESULT = 'hadith_narr_comm_result',
    COMMENTATOR_FILTERS = 'hadith_narr_comm_commentator_filters',
    BOOKS_FILTERS = 'hadith_narr_comm_books_filters',
    NARRATOR_ID = 'hadith_narrator-id',
}

// for narrator commentary in /narrators page
export enum NarratorCommentaryViewFilters {
    RESULT = 'narr_comm_result',
    COMMENTATOR_FILTERS = 'narr_comm_commentator_filters',
    BOOKS_FILTERS = 'narr_comm_books_filters',
    NARRATOR_ID = 'narrator-id',
}

export enum AmbiguousViewFilters {
    RESULT = 'ambg_result',
    AMBIGUOUS_ID = 'ambg_id',
    AMBIGUOUS_BOOK = 'ambg_book',
    AMBIGUOUS_AUTHOR = 'ambg_author',
}

export enum RoadsHadithsViewFilters {
    RESULT = 'roads_result',
    BOOKS_FILTERS = 'roads_books_filters',
    NARRATORS_FILTERS = 'roads_narrators_filters',
    NARRATORS_SEARCH = 'roads_narrators_search',
    CHAPTERS_FILTERS = 'roads_chapters_filters',
    SUB_CHAPTERS_FILTERS = 'roads_sub_chapters_filters',
    HADITH_RULINGS_FILTERS = 'roads_hadith_rulings_filters',
    RULINGS_SEARCH = 'roads_hadith_rulings_filters_search',
    HADITH_TYPES_FILTERS = 'roads_hadith_types_filters',
    ROOT_KEYWORDS_FILTERS = 'roads_root_keywords _filters',
    HADITHS_IDS = 'hadith_ids',
    CHAPTERS_SEARCH = 'roads_chapters_search',
    SUB_CHAPTERS_SEARCH = 'roads_sub_chapters_search',
}

export enum RoadsHadithsGraphViewFilters {
    RESULT = 'roads_result',
    HADITHS_IDS = 'hadith_idsgraph',
}

export enum HadithExplanationViewFilters {
    RESULT = 'expl_result',
    SEARCH = 'search',
    EXPLANATION_BOOKS_AUTHORS_FILTERS = 'expl_books_authors_filters',
    EXPLANATION_BOOKS_FILTERS = 'expl_explanation_books_filters',
    BOOKS_FILTERS = 'expl_books_filters',
    CHAPTERS_FILTERS = 'expl_chapters_filters',
    CHAPTERS_SEARCH = 'expl_chapters_search',
    SUB_CHAPTERS_FILTERS = 'expl_sub_chapters_filters',
    SUB_CHAPTERS_SEARCH = 'expl_sub_chapters_search',
    HADITHS_IDS = 'hadith_ids',
}

export enum HadithRulingsViewFilters {
    RESULT = 'rul_result',
    BOOKS_FILTERS = 'rul_books_filters',
    NARRATORS_FILTERS = 'rul_narrators_filters',
    NARRATORS_SEARCH = 'rul_narrators_search',
    RULERS_FILTERS = 'rul_rulers_filters',
    RULING_FILTERS = 'rul_ruling_filters',
    HADITH_ID = 'rul_hadith_id',
}

export enum HadithCommentaryViewFilters {
    RESULT = 'comm_result',
    BOOKS_FILTERS = 'comm_books_filters',
    COMMENTATOR_FILTERS = 'comm_books_commentator_filters',
    HADITHS_IDS = 'hadith_ids',
}

// state to retain when going into modal
export const mainSearchStateKeys = [
    // main page state
    SearchFilters.SEARCH,
    SearchFilters.NARRATORS_LINKED,
    SearchFilters.HADITH_NUMBER_SEARCH,
    SearchFilters.SEARCH_TYPE,
    SearchFilters.BOOKS_FILTERS,
    SearchFilters.NARRATORS_FILTERS,
    SearchFilters.CHAPTERS_FILTERS,
    SearchFilters.SUB_CHAPTERS_FILTERS,
    SearchFilters.HADITH_TYPES_FILTERS,

    // to not lose the sort order when going into the modal
    'sort',

    // narrator scholar state from /narrators page
    NarratorScholarsViewFilters.NARRATOR_ID,
    NarratorScholarsViewFilters.SCHOLARS_FILTERS,
    NarratorScholarsViewFilters.BOOKS_FILTERS,
    NarratorScholarsViewFilters.GRADES_FILTERS,
    NarratorScholarsViewFilters.HADITH_VERB_FILTERS,
    NarratorScholarsViewFilters.TYPES_FILTERS,

    // narrator student state from /narrators page
    NarratorStudentsViewFilters.NARRATOR_ID,
    NarratorStudentsViewFilters.STUDENTS_FILTERS,
    NarratorStudentsViewFilters.BOOKS_FILTERS,
    NarratorStudentsViewFilters.GRADES_FILTERS,
    NarratorStudentsViewFilters.HADITH_VERB_FILTERS,
    NarratorStudentsViewFilters.TYPES_FILTERS,

    // AmbiguousViewFilters.AMBIGUOUS_ID,

    // from narrators page: which narrator tab you're on (bio, scholar, student, commentary)
    // and which narrator
    'narratorView',
    'narratorId',
];
