export const BookIcon = () => {
    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            style={{
                marginBottom: '8px',
            }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.25 4C3.25 3.44772 3.69772 3 4.25 3H12.25C12.8023 3 13.25 3.44772 13.25 4V6C13.25 6.55228 12.8023 7 12.25 7H4.25C3.69772 7 3.25 6.55228 3.25 6V4ZM4.75 5.5H11.75V4.5H4.75V5.5ZM0.25 2.5C0.25 1.11929 1.36929 0 2.75 0H14.25C15.6307 0 16.75 1.11929 16.75 2.5V16.75C16.75 17.1642 16.4142 17.5 16 17.5H1.75C1.75 18.0523 2.19772 18.5 2.75 18.5H16C16.4142 18.5 16.75 18.8358 16.75 19.25C16.75 19.6642 16.4142 20 16 20H2.75C1.36929 20 0.25 18.8807 0.25 17.5V2.5ZM1.75 16H15.25V2.5C15.25 1.94772 14.8023 1.5 14.25 1.5H2.75C2.19772 1.5 1.75 1.94772 1.75 2.5V16Z"
                fill="#B7A4A4"
            />
        </svg>
    );
};

export const BookFilledIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            style={{
                marginBottom: '8px',
            }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.75 2C5.36929 2 4.25 3.11929 4.25 4.5V19.5C4.25 20.8807 5.36929 22 6.75 22H20C20.4142 22 20.75 21.6642 20.75 21.25C20.75 20.8358 20.4142 20.5 20 20.5H6.75C6.19772 20.5 5.75 20.0523 5.75 19.5H20C20.4142 19.5 20.75 19.1642 20.75 18.75V4.5C20.75 3.11929 19.6307 2 18.25 2H6.75ZM8.25 5H16.25C16.8023 5 17.25 5.44772 17.25 6V7C17.25 7.55228 16.8023 8 16.25 8H8.25C7.69772 8 7.25 7.55228 7.25 7V6C7.25 5.44772 7.69772 5 8.25 5Z"
                fill="#C7956D"
            />
        </svg>
    );
};
