import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 17 13" {...props}>
            <path d="M5.95507 8.88738C6.08616 8.70634 6.15487 8.58319 6.24979 8.48669C8.83632 5.85554 11.4247 3.22531 14.0148 0.597832C14.7634 -0.161278 15.7958 -0.199876 16.491 0.495821C17.1166 1.12259 17.1727 2.11789 16.6194 2.81267C16.5416 2.91008 16.4521 2.99923 16.3645 3.08837C13.3494 6.16065 10.3334 9.23293 7.31659 12.3043C6.40258 13.2353 5.46326 13.2307 4.54563 12.2979C3.204 10.934 1.85514 9.57757 0.523455 8.20363C-0.434853 7.21477 -0.0162714 5.61384 1.28196 5.28023C1.92294 5.11573 2.48618 5.29678 2.95539 5.77375C3.8495 6.68358 4.74543 7.59157 5.63774 8.50231C5.72453 8.59146 5.79776 8.69439 5.95507 8.88646V8.88738Z" />
        </SvgIcon>
    );
};

export default CheckIcon;
