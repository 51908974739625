export enum NarratorViews {
    BIO = 'bio',
    SCHOLARS = 'scholars',
    STUDENTS = 'students',
    COMMENTARY = 'commentary',
}

export enum HadithViews {
    NARRATOR = 'narrators',
    ROADS = 'roads',
    COMMENTARY = 'commentary',
    EXPLANATION = 'explanation',
    RULINGS = 'rulings',
    AMBIGUOUS_WORDS = 'ambiguous',
}

export enum RoadsViews {
    ROADS = 'roads',
    GRAPH = 'graph',
}
