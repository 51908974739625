// @ts-nocheck
// There are no types for this library in the definitely-typed (@types) project. We can make a PR ourselves.
import { load } from 'cheerio';
import DiffMatchPatch from 'diff-match-patch-with-word';

const displayMatnComparison = (first = '', second = '', fontSize: string) => {
    const dm = new DiffMatchPatch();
    const b = dm.diff_linesToWords_(first, second);
    const lineArray = b.lineArray;
    const lineText1 = b.chars1;
    const lineText2 = b.chars2;
    const d = dm.diff_main(lineText1, lineText2, false);
    dm.diff_charsToLines_(d, lineArray);
    const ds = dm.diff_prettyHtml(d);

    const $ = load(ds);
    $('*').css({
        fontSize: fontSize,
    });

    $('ins').css({
        padding: '3px 5px',
        'border-radius': '10px',
    });

    $('del').css({
        padding: '3px 5px',
        'border-radius': '10px',
    });

    // often there are white space differences between the two matns
    // so remove any diff nodes (deletions or insertions) whose contents are
    // just whitespace
    $('del, ins').each((_, el) => {
        const text = $(el).text().trim();

        if (text.length === 0) {
            // empty contents
            $(el).remove();
        }
    });

    return $.html();
};

export { displayMatnComparison as default };
