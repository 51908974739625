import { type ReactElement, useEffect, useState } from 'react';

import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { AiSvg } from '@components/AiSvg';
import { BookFilledIcon, BookIcon } from '@components/icons/Book';
import { MoreIcon } from '@components/icons/More';
import { PersonFilledIcon, PersonIcon } from '@components/icons/Person';
import { SettingIcon, SettingsFilledIcon } from '@components/icons/Settings';

import { moreNavbarItems } from '@config/navbar';
import useTranslation from '@hooks/useTranslation';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Divider,
    Drawer,
    Link,
    List,
    Paper,
    Slide,
    Typography,
    useScrollTrigger,
} from '@mui/material';

import { colors } from 'theme';

interface Props {
    children: ReactElement;
}

const hrefs = ['/', '/ai', '/narrators', '/settings'];

function HideOnScroll(props: Props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        target: undefined,
    });
    return (
        <Slide appear={false} direction="up" in={!trigger}>
            {children}
        </Slide>
    );
}

export function NavDrawer({
    mobileOpen,
    setMobileOpen,
    setBottomNavValue,
}: {
    mobileOpen: boolean;
    setMobileOpen: (x: boolean) => void;
    setBottomNavValue: (x: number) => void;
}) {
    const { t } = useTranslation('layout');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const router = useRouter();

    const container =
        typeof window !== 'undefined' ? () => window.document.body : undefined;

    return (
        <Box component="nav">
            <Drawer
                container={container}
                anchor="right"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: 240,
                    },
                }}
            >
                <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                    <Box
                        display={'flex'}
                        alignItems="center"
                        justifyContent={'center'}
                        flexDirection={'row'}
                        p={1}
                        gap={1}
                        width="100%"
                    >
                        <Image
                            alt="logo"
                            src="/images/logo-vertical.png"
                            width={64}
                            height={77}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setBottomNavValue(0);
                                router.push('/');
                            }}
                        />
                    </Box>

                    <Divider />
                    <List
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                        }}
                    >
                        {moreNavbarItems.map(({ label, path, pathname }) => (
                            <NextLink
                                href={path}
                                key={label}
                                style={{
                                    width: '100%',
                                }}
                                onClick={() => {
                                    setBottomNavValue(hrefs.length);
                                }}
                            >
                                <Typography
                                    key={label}
                                    sx={{
                                        py: 2,
                                        pl: 2,
                                        borderRadius: 0,
                                        '&:hover': {
                                            borderBottom: `2px solid ${colors.secondary}`,
                                        },
                                        textAlign: 'left',
                                        fontWeight:
                                            router.pathname === pathname
                                                ? 600
                                                : 'unset',
                                        color:
                                            router.pathname === pathname
                                                ? colors.primary
                                                : '#121212',
                                    }}
                                >
                                    {t(`${label}`)}
                                </Typography>
                            </NextLink>
                        ))}
                    </List>
                    <Box
                        width="100%"
                        position="absolute"
                        bottom={5}
                        textAlign="center"
                    >
                        <p>{t('follow_us')}</p>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                justifyContent: 'center',
                            }}
                        >
                            <Link
                                href="https://www.facebook.com/profile.php?id=61563783331635"
                                target="_blank"
                            >
                                <FacebookIcon color="primary" />
                            </Link>
                            <Link
                                href="https://x.com/alminasaai"
                                target="_blank"
                            >
                                <XIcon color="primary" />
                            </Link>
                            <Link
                                href="https://t.me/alminasaai"
                                target="_blank"
                            >
                                <TelegramIcon color="primary" />
                            </Link>
                            <Link
                                href="https://www.youtube.com/channel/UCp1yewzLiNK2W_xM_lFK_DA"
                                target="_blank"
                            >
                                <YouTubeIcon color="primary" />
                            </Link>
                            <Link
                                href="https://www.instagram.com/alminasaai/"
                                target="_blank"
                            >
                                <InstagramIcon color="primary" />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

export function BottomNav() {
    const [value, setValue] = useState(0);
    const { t } = useTranslation('layout');
    const router = useRouter();

    const [mobileOpen, setMobileOpen] = useState(false);

    // sync the bottom nav value with the incoming url
    useEffect(() => {
        if (hrefs.includes(router.pathname)) {
            setValue(hrefs.findIndex((href) => href === router.pathname));
        } else {
            setValue(hrefs.length);
        }
    }, []);

    return (
        <>
            <HideOnScroll>
                <Paper
                    className="bottom-safe-area"
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        py: 1.5,
                    }}
                    elevation={5}
                >
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(_event, newValue) => {
                            if ([0, 1, 2, 3].includes(newValue)) {
                                router.push(hrefs[newValue]);
                                setValue(newValue);
                            } else if (newValue === hrefs.length) {
                                setMobileOpen(true);
                            }
                        }}
                    >
                        <BottomNavigationAction
                            label={t('navbar_link_hadiths')}
                            sx={{
                                '& .Mui-selected': {
                                    color:
                                        value === 0
                                            ? colors.secondary
                                            : '#B7A4A4', // Color for the selected label
                                },
                            }}
                            icon={
                                value === 0 ? <BookFilledIcon /> : <BookIcon />
                            }
                        />
                        <BottomNavigationAction
                            label={t('navbar_link_ai')}
                            sx={{
                                '& .Mui-selected': {
                                    color:
                                        value === 1
                                            ? colors.secondary
                                            : '#B7A4A4', // Color for the selected label
                                },
                            }}
                            icon={
                                <AiSvg
                                    width={value === 1 ? 35 : 25}
                                    height={value === 1 ? 35 : 24}
                                    color={
                                        value === 1
                                            ? colors.secondary
                                            : '#B7A4A4'
                                    }
                                />
                            }
                        />
                        <BottomNavigationAction
                            label={t('navbar_link_narrators')}
                            sx={{
                                '& .Mui-selected': {
                                    color:
                                        value === 2
                                            ? colors.secondary
                                            : '#B7A4A4', // Color for the selected label
                                },
                            }}
                            icon={
                                value === 2 ? (
                                    <PersonFilledIcon />
                                ) : (
                                    <PersonIcon />
                                )
                            }
                        />
                        <BottomNavigationAction
                            label={t('navbar_settings_references')}
                            sx={{
                                '& .Mui-selected': {
                                    color:
                                        value === 3
                                            ? colors.secondary
                                            : '#B7A4A4', // Color for the selected label
                                },
                            }}
                            icon={
                                value === 3 ? (
                                    <SettingsFilledIcon />
                                ) : (
                                    <SettingIcon />
                                )
                            }
                        />
                        <BottomNavigationAction
                            label={t('navbar_link_more')}
                            sx={{
                                '& .Mui-selected': {
                                    color:
                                        value === 4
                                            ? colors.secondary
                                            : '#B7A4A4', // Color for the selected label
                                },
                            }}
                            icon={<MoreIcon />}
                        />
                    </BottomNavigation>
                </Paper>
            </HideOnScroll>
            <NavDrawer
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
                setBottomNavValue={setValue}
            />
        </>
    );
}
