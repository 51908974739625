import {
    type ChangeEvent,
    Dispatch,
    type FocusEvent,
    type KeyboardEvent,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';

import Image from 'next/image';
import {
    FullScreen,
    type FullScreenHandle,
    useFullScreenHandle,
} from 'react-full-screen';
import { useSwipeable } from 'react-swipeable';

import { CustomTooltip } from '@components/CustomTooltip';

import useTranslation from '@hooks/useTranslation';
import { Cancel as CancelIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PageviewIcon from '@mui/icons-material/Pageview';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    ListSubheader,
    ListSubheaderProps,
    MenuItem,
    MenuItemProps,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { a2e } from '@utils/arabicToEnglishNumbers';

import { BookInfo, booksBasePath, booksConfig } from './bookConfigs';
import { type VolumesStartEnd } from './bookConfigs';
import fscreen from 'fscreen';
import type { BookReference, EditionRefs } from 'shared/interfaces/hadith';

// This is necessary because the children of select need to have
// a 'value' prop for the select functionality to work
// so we cannot simply do
// <>
// <ListSubheader/>
// <MenuItem/>
// </>
// because the components in between <> and </> don't have a 'value' prop
// so we create a wrapper component that does!
function MenuItemWithListSubheader({
    listSubheaderProps,
    showListSubheader,
    listSubheaderLabel,
    ...menuItemProps
}: MenuItemProps & {
    listSubheaderProps: ListSubheaderProps;
    showListSubheader: boolean;
    listSubheaderLabel?: string;
}) {
    return (
        <>
            {showListSubheader && (
                <ListSubheader {...listSubheaderProps}>
                    <ArrowBackIcon fontSize="small" />
                    {listSubheaderLabel}
                </ListSubheader>
            )}
            <MenuItem {...menuItemProps}>{menuItemProps.children}</MenuItem>
        </>
    );
}

const Controls = ({
    selectedVolume,
    setSelectedVolume,
    selectedPage,
    currentInputPage,
    setCurrentInputPage,
    setSelectedPage,
    volumesStartEnd,
    setPreviousSelectedPage,
    previousSelectedPage,
    fullScreenHandle,
    totalVolume,
}: {
    volumesStartEnd: VolumesStartEnd;
    selectedVolume: number;
    setSelectedVolume: Dispatch<SetStateAction<number>>;
    selectedPage: number;
    previousSelectedPage: number | null;
    setPreviousSelectedPage: Dispatch<SetStateAction<number | null>>;
    setSelectedPage: Dispatch<SetStateAction<number>>;
    currentInputPage: number;
    setCurrentInputPage: Dispatch<SetStateAction<number>>;
    fullScreenHandle: FullScreenHandle;
    totalVolume: string;
}) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const { t } = useTranslation('library');
    const [showControls, setShowControls] = useState(true);
    const [inputFocused, setInputFocused] = useState(false);
    const [mouseOverControls, setMouseOverControls] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        const resetTimer = () => {
            if (!showControls || inputFocused || mouseOverControls) {
                setShowControls(true);
            }
            clearTimeout(timer);
            timer = setTimeout(() => setShowControls(false), 3000);
        };

        const handleMouseMove = () => {
            resetTimer();
        };

        const handleMouseEnter = () => {
            setMouseOverControls(true);
            resetTimer();
        };

        const handleMouseLeave = () => {
            setMouseOverControls(false);
            resetTimer();
        };

        const handleTouchStart = () => {
            resetTimer();
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseenter', handleMouseEnter);
        window.addEventListener('mouseleave', handleMouseLeave);
        window.addEventListener('touchstart', handleTouchStart);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseenter', handleMouseEnter);
            window.removeEventListener('mouseleave', handleMouseLeave);
            window.removeEventListener('touchstart', handleTouchStart);
            clearTimeout(timer);
        };
    }, [showControls, inputFocused, mouseOverControls]);

    const handleInputFocus = () => {
        setInputFocused(true);
        const inputElement = document.getElementById(
            'inputFieldBookprev',
        ) as HTMLInputElement;
        if (inputElement) {
            inputElement.setSelectionRange(0, inputElement.value.length);
        }
    };

    const handleInputFocusSelect = () => {
        setInputFocused(true);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
        setInputFocused(false);
        if (event.target.value) {
            const inputValue = Number(a2e(event.target.value.toString()));
            if (Number.isInteger(inputValue) && inputValue !== 0) {
                if (inputValue > volumesStartEnd[selectedVolume][1]) {
                    setSelectedPage(volumesStartEnd[selectedVolume][1]);
                    setPreviousSelectedPage(volumesStartEnd[selectedVolume][1]);
                    setCurrentInputPage(volumesStartEnd[selectedVolume][1]);
                } else if (inputValue < 1) {
                    setSelectedPage(volumesStartEnd[selectedVolume][0]);
                    setPreviousSelectedPage(volumesStartEnd[selectedVolume][0]);
                    setCurrentInputPage(volumesStartEnd[selectedVolume][0]);
                } else {
                    setSelectedPage(inputValue);
                    setPreviousSelectedPage(inputValue);
                    setCurrentInputPage(inputValue);
                }
            } else if (inputValue === 0) {
                if (previousSelectedPage) {
                    setSelectedPage(previousSelectedPage);
                    setCurrentInputPage(previousSelectedPage);
                }
            }
        }
    };

    const handleChangePage = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = Number(a2e(event.target.value));
        if (Number.isInteger(inputValue)) setCurrentInputPage(inputValue);
    };

    const handleMouseOverControls = () => {
        setMouseOverControls(true);
    };

    const handleMouseLeaveControls = () => {
        setMouseOverControls(false);
    };

    const handleChangeVolume = (
        event: SelectChangeEvent<typeof selectedVolume>,
    ) => {
        setSelectedVolume(event.target.value as number);
        setSelectedPage(volumesStartEnd[event.target.value as number][0]); // reset on changing the volume
        setCurrentInputPage(volumesStartEnd[event.target.value as number][0]);
    };

    const handleInputBlurSelect = () => {
        setInputFocused(false);
    };

    const fullScreenToggleButton = fullScreenHandle.active ? (
        <IconButton
            onClick={fullScreenHandle.exit}
            sx={{
                backgroundColor: '#121212BF',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: '#121212BF',
                },
            }}
        >
            <FullscreenExitIcon sx={{ color: '#fff' }} />
        </IconButton>
    ) : (
        <Box>
            <IconButton
                onClick={() => {
                    if (fscreen.fullscreenEnabled) {
                        fullScreenHandle.enter();
                    } else {
                        setOpenTooltip(true);
                        setTimeout(() => setOpenTooltip(false), 3000);
                    }
                }}
                sx={{
                    backgroundColor: '#121212BF',
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#121212BF',
                    },
                }}
            >
                <CustomTooltip
                    message={t('full_screen_disabled')}
                    open={openTooltip}
                    sx={{ position: 'absolute' }}
                />
                <FullscreenIcon
                    sx={{ color: fscreen.fullscreenEnabled ? '#fff' : '#999' }}
                />
            </IconButton>
        </Box>
    );

    return (
        <Box
            sx={{
                width: { xs: '330px', md: '400px' },
                position: 'fixed',
                bottom: { xs: 65, md: 40 },
                left: '50%',
                transform: 'translateX(-50%)',
                visibility:
                    showControls || inputFocused || mouseOverControls
                        ? 'visible'
                        : 'hidden',
            }}
            onMouseEnter={handleMouseOverControls}
            onMouseLeave={handleMouseLeaveControls}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    zIndex: 2,
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#121212BF',
                                borderRadius: '5px',
                                borderTopRightRadius: '0px',
                                borderBottomRightRadius: '0px',
                                borderRight: '1px solid #838383',
                                '&:hover': {
                                    backgroundColor: '#121212BF',
                                },
                            }}
                        >
                            <LastPageIcon
                                sx={{
                                    color: '#fff',
                                    cursor: 'pointer',
                                    zIndex: 2,
                                }}
                                onClick={() => {
                                    setSelectedPage(
                                        volumesStartEnd[selectedVolume][0],
                                    );
                                    setCurrentInputPage(
                                        volumesStartEnd[selectedVolume][0],
                                    );
                                }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                backgroundColor: '#121212BF',
                                borderRadius: '5px',
                                borderTopLeftRadius: '0px',
                                borderBottomLeftRadius: '0px',
                                '&:hover': {
                                    backgroundColor: '#121212BF',
                                },
                            }}
                        >
                            <NavigateNextIcon
                                color="secondary"
                                sx={{
                                    color: '#fff',
                                    zIndex: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setSelectedPage(
                                        selectedPage ==
                                            volumesStartEnd[selectedVolume][0]
                                            ? volumesStartEnd[selectedVolume][0]
                                            : selectedPage - 1,
                                    );
                                    setCurrentInputPage(
                                        selectedPage ==
                                            volumesStartEnd[selectedVolume][0]
                                            ? volumesStartEnd[selectedVolume][0]
                                            : selectedPage - 1,
                                    );
                                }}
                            />
                        </IconButton>
                    </Box>
                    {fullScreenToggleButton}
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#121212BF',
                                borderRadius: '5px',
                                borderTopRightRadius: '0px',
                                borderBottomRightRadius: '0px',
                                borderRight: '1px solid #838383',
                                '&:hover': {
                                    backgroundColor: '#121212BF',
                                },
                            }}
                        >
                            <NavigateBeforeIcon
                                sx={{
                                    color: '#fff',
                                    zIndex: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setSelectedPage(
                                        selectedPage <
                                            volumesStartEnd[selectedVolume][1]
                                            ? selectedPage + 1
                                            : selectedPage,
                                    );
                                    setCurrentInputPage(
                                        selectedPage <
                                            volumesStartEnd[selectedVolume][1]
                                            ? selectedPage + 1
                                            : selectedPage,
                                    );
                                }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                backgroundColor: '#121212BF',
                                borderRadius: '5px',
                                borderTopLeftRadius: '0px',
                                borderBottomLeftRadius: '0px',
                                '&:hover': {
                                    backgroundColor: '#121212BF',
                                },
                            }}
                        >
                            <FirstPageIcon
                                sx={{
                                    color: '#fff',
                                    zIndex: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setSelectedPage(
                                        volumesStartEnd[selectedVolume][1],
                                    );
                                    setCurrentInputPage(
                                        volumesStartEnd[selectedVolume][1],
                                    );
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        background: '#121212BF',
                        p: 1,
                        borderRadius: '5px',
                        display: 'flex',
                        gap: 0.5,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', sm: 'initial' },
                                color: '#fff',
                            }}
                        >
                            صفحة
                        </Typography>
                        <TextField
                            size="small"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '([0-9]+ | [٠-٩]+)',
                            }}
                            sx={{
                                width: { xs: '60px', sm: '80px' },
                                height: '40px',
                                input: {
                                    color:
                                        currentInputPage === 0
                                            ? '#ddd'
                                            : '#fff',
                                    fontSize: '14px',
                                },
                                backgroundColor: '#000000B2',
                                borderRadius: '5px',
                                outline: 'none',
                                border: 'none',
                                '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                },
                            }}
                            value={currentInputPage}
                            id="inputFieldBookprev"
                            onChange={handleChangePage}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onKeyDown={(
                                event: KeyboardEvent<HTMLInputElement>,
                            ) => {
                                if (event.key === 'Enter') {
                                    if (currentInputPage) {
                                        if (
                                            Number.isInteger(
                                                currentInputPage,
                                            ) &&
                                            currentInputPage !== 0
                                        ) {
                                            if (
                                                currentInputPage >
                                                volumesStartEnd[
                                                    selectedVolume
                                                ][1]
                                            ) {
                                                setSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                                setPreviousSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                                setCurrentInputPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                            } else if (currentInputPage < 1) {
                                                setSelectedPage(1);
                                                setPreviousSelectedPage(1);
                                                setCurrentInputPage(1);
                                            } else {
                                                setSelectedPage(
                                                    currentInputPage,
                                                );
                                                setPreviousSelectedPage(
                                                    currentInputPage,
                                                );
                                            }
                                        } else if (currentInputPage === 0) {
                                            if (previousSelectedPage)
                                                setSelectedPage(
                                                    previousSelectedPage,
                                                );
                                        }
                                    }
                                } else if (event.key === 'ArrowUp') {
                                    if (
                                        selectedPage <
                                        volumesStartEnd[selectedVolume][1]
                                    ) {
                                        setSelectedPage(selectedPage + 1);
                                        setPreviousSelectedPage(
                                            selectedPage + 1,
                                        );
                                        setCurrentInputPage(selectedPage + 1);
                                    }
                                } else if (event.key === 'ArrowDown') {
                                    if (selectedPage > 1) {
                                        setSelectedPage(selectedPage - 1);
                                        setCurrentInputPage(selectedPage - 1);
                                    }
                                } else {
                                    event.stopPropagation();
                                }
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', sm: 'initial' },
                                color: '#fff',
                            }}
                        >
                            {`/${volumesStartEnd[selectedVolume][1]}`}
                        </Typography>
                    </Box>
                    <Divider
                        orientation="vertical"
                        sx={{
                            backgroundColor: '#000000B2',
                            color: '#000000B2',
                        }}
                    />
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'inherit',
                                },
                            }}
                        >
                            <PageviewIcon
                                sx={{
                                    color: '#fff',
                                    zIndex: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (currentInputPage) {
                                        if (
                                            Number.isInteger(
                                                currentInputPage,
                                            ) &&
                                            currentInputPage !== 0
                                        ) {
                                            if (
                                                currentInputPage >
                                                volumesStartEnd[
                                                    selectedVolume
                                                ][1]
                                            ) {
                                                setSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                                setPreviousSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                            } else if (
                                                currentInputPage <
                                                volumesStartEnd[
                                                    selectedVolume
                                                ][1]
                                            ) {
                                                setSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                                setPreviousSelectedPage(
                                                    volumesStartEnd[
                                                        selectedVolume
                                                    ][1],
                                                );
                                            } else {
                                                setSelectedPage(
                                                    currentInputPage,
                                                );
                                                setPreviousSelectedPage(
                                                    currentInputPage,
                                                );
                                            }
                                        } else if (currentInputPage === 0) {
                                            if (previousSelectedPage)
                                                setSelectedPage(
                                                    previousSelectedPage,
                                                );
                                        }
                                    }
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', sm: 'initial' },
                                color: '#fff',
                            }}
                        >
                            جزء
                        </Typography>

                        <Select
                            size="small"
                            MenuProps={
                                {
                                    // disablePortal: true
                                }
                            }
                            sx={{
                                width: { xs: '60px', sm: '80px' },
                                height: '40px',
                                input: { color: '#fff', fontSize: '14px' },
                                backgroundColor: '#000000B2',
                                borderRadius: '5px',
                                outline: 'none',
                                zIndex: '999 !important',
                                border: 'none',
                                '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                },
                            }}
                            onChange={handleChangeVolume}
                            onFocus={handleInputFocusSelect}
                            onBlur={handleInputBlurSelect}
                            value={selectedVolume}
                            renderValue={(selected) => (
                                <span style={{ color: '#fff' }}>
                                    {selected}
                                </span>
                            )}
                        >
                            {Object.keys(volumesStartEnd).map((volume) => {
                                return (
                                    <MenuItem
                                        key={volume}
                                        value={volume}
                                        sx={{
                                            zIndex: '999 !important',
                                        }}
                                    >
                                        {volume}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', sm: 'initial' },
                                color: '#fff',
                            }}
                        >
                            {`/${totalVolume}`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

// TODO: This structure contains some repition due to recursion, but it's not that bad
// fix...
// We need this because the primary edition is in the base object bookConfig, not in bookConfig.editions
// so to keep uniform and backward compatible, we duplicate it into the 'bookConfig.editions' array
function getEditions(bookConfig: BookInfo) {
    return bookConfig.editions
        ? {
              [bookConfig.bookCopy]: bookConfig,
              ...bookConfig.editions,
          }
        : {
              [bookConfig.bookCopy]: bookConfig,
          };
}

function editionRefAvailable(edition: string, editionsRefs?: EditionRefs) {
    const editionRef = editionsRefs?.find((ed) => ed.edition === edition);
    if (editionRef) {
        return editionRef.page && editionRef.volume ? true : false;
    }

    return false;
}

// - If only one edition is available, the dropdown is filled but disabled
// - If more than one edition is available, they're shown in the dropdown on both desktop and mobile
// - If the hadith doesn't have a ref for any edition, the edition is shown in the dropdown but disabled
const BookPreview = ({
    open,
    setOpen,
    initNavPointId,
    quickNavPoints,
    initEditionsRefs,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    initNavPointId: string;
    quickNavPoints: BookReference[];
    initEditionsRefs: EditionRefs;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation('references');

    const initNavPoint = quickNavPoints.find(
        (navPoint) => navPoint.id === initNavPointId,
    )!;

    const initBookConfig = booksConfig[initNavPoint.book_name];

    const fullScreenHandle = useFullScreenHandle();

    const [selectedVolume, setSelectedVolume] = useState(initNavPoint.volume);
    const [selectedPage, setSelectedPage] = useState(initNavPoint.page);
    const [currentInputPage, setCurrentInputPage] = useState(selectedPage);
    const [previousSelectedPage, setPreviousSelectedPage] = useState<
        number | null
    >(initNavPoint.page);
    const [quickNavPoint, setQuickNavPoint] = useState(initNavPoint);

    const [editions, setEditions] = useState(getEditions(initBookConfig));
    const [bookConfig, setBookConfig] = useState(initBookConfig);
    const [editionsRefs, setEditionsRefs] = useState(initEditionsRefs);

    const groupsFirstIndices = useMemo(() => {
        const groups = [
            ...new Set(quickNavPoints.map((point) => point.group)),
        ].filter((group): group is string => !!group);

        return groups.map((group) =>
            quickNavPoints.findIndex((point) => point.group === group),
        );
    }, [quickNavPoints]);

    // due to image processing pipeline, so these numbers are coming from the backend / source
    const previewWidth = 1534;
    const previewHeight = 2172;

    const images = [
        <Image
            key={`${bookConfig.bookName}-volume${selectedVolume}-page${
                selectedPage
                    ? selectedPage - 1
                    : previousSelectedPage
                    ? previousSelectedPage - 1
                    : selectedPage
            }`}
            placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8PjW3HgAGPgJK9wOnxwAAAABJRU5ErkJggg=="
            src={`${booksBasePath}/${bookConfig.bookPath}/${selectedVolume}/${
                selectedPage
                    ? selectedPage - 1
                    : previousSelectedPage
                    ? previousSelectedPage - 1
                    : selectedPage
            }.png`}
            alt={`Page ${
                selectedPage
                    ? selectedPage - 1
                    : previousSelectedPage
                    ? previousSelectedPage - 1
                    : selectedPage
            } of volume ${selectedVolume} of book ${bookConfig.bookName}`}
            width={previewWidth}
            height={previewHeight}
            style={{
                display: 'none',
                width: '100%',
                height: 'auto',
                // these minima to minimize layout shifts
                minHeight: '60vh',
                minWidth: '100%',
            }}
        />,

        <Image
            key={`${bookConfig.bookName}-volume${selectedVolume}-page${
                selectedPage ? selectedPage : previousSelectedPage
            }`}
            src={`${booksBasePath}/${bookConfig.bookPath}/${selectedVolume}/${
                selectedPage ? selectedPage : previousSelectedPage
            }.png`}
            placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8PjW3HgAGPgJK9wOnxwAAAABJRU5ErkJggg=="
            alt={`Page ${
                selectedPage ? selectedPage : previousSelectedPage
            } of volume ${selectedVolume} of book ${bookConfig.bookName}`}
            width={previewWidth}
            height={previewHeight}
            style={{
                width: '100%',
                height: 'auto',
                // these minima to minimize layout shifts
                minHeight: '60vh',
                minWidth: '100%',
            }}
        />,

        <Image
            key={`${bookConfig.bookName}-volume${selectedVolume}-page${
                selectedPage
                    ? selectedPage + 1
                    : previousSelectedPage
                    ? previousSelectedPage + 1
                    : selectedPage
            }`}
            placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8PjW3HgAGPgJK9wOnxwAAAABJRU5ErkJggg=="
            src={`${booksBasePath}/${bookConfig.bookPath}/${selectedVolume}/${
                selectedPage
                    ? selectedPage + 1
                    : previousSelectedPage
                    ? previousSelectedPage + 1
                    : selectedPage
            }.png`}
            alt={`Page ${
                selectedPage
                    ? selectedPage + 1
                    : previousSelectedPage
                    ? previousSelectedPage + 1
                    : selectedPage
            } of volume ${selectedVolume} of book ${bookConfig.bookName}`}
            width={previewWidth}
            height={previewHeight}
            style={{
                display: 'none',
                width: '100%',
                height: 'auto',
                // these minima to minimize layout shifts
                minHeight: '60vh',
                minWidth: '100%',
            }}
        />,
    ];
    const swipeHandlers = useSwipeable({
        preventScrollOnSwipe: true,
        swipeDuration: 250,
        onSwipedLeft: () => {
            setSelectedPage((prevPage: number) =>
                prevPage > 1 ? prevPage - 1 : prevPage,
            );
            setCurrentInputPage((prevPage: number) =>
                prevPage > 1 ? prevPage - 1 : prevPage,
            );
        },
        onSwipedRight: () => {
            setSelectedPage((prevPage: number) =>
                prevPage < bookConfig.volumesPage[selectedVolume][1]
                    ? prevPage + 1
                    : prevPage,
            );
            setCurrentInputPage((prevPage: number) =>
                prevPage < bookConfig.volumesPage[selectedVolume][1]
                    ? prevPage + 1
                    : prevPage,
            );
        },
    });

    function QuickNavPointsSelector() {
        return (
            <Box
                sx={{
                    paddingX: isMobile ? 2 : undefined,
                }}
            >
                <FormControl variant="standard">
                    <InputLabel id="quick-navigation">
                        {t('quick_navigation')}
                    </InputLabel>
                    <Select
                        size="small"
                        labelId="narrator-label"
                        value={JSON.stringify(quickNavPoint)}
                        variant="filled"
                        label={t('quick_navigation')}
                        onChange={(event) => {
                            const parsed = JSON.parse(
                                event.target.value,
                            ) as BookReference;
                            setBookConfig(booksConfig[parsed.book_name]);
                            setSelectedVolume(parsed.volume);
                            setSelectedPage(parsed.page);
                            setCurrentInputPage(parsed.page);
                            setQuickNavPoint(parsed);
                            setEditionsRefs(parsed.editionsRefs);

                            // update the editions since the book has changed
                            setEditions(
                                getEditions(booksConfig[parsed.book_name]),
                            );
                        }}
                        sx={{
                            width: isMobile ? '80vw' : '360px',
                        }}
                        MenuProps={{
                            disablePortal: true,
                        }}
                    >
                        {quickNavPoints.map((navPoint, index) => (
                            <MenuItemWithListSubheader
                                showListSubheader={groupsFirstIndices.includes(
                                    index,
                                )}
                                listSubheaderProps={{
                                    sx: {
                                        color: 'secondary.main',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                }}
                                value={JSON.stringify(navPoint)}
                                key={quickNavPoint.id}
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'wrap',
                                }}
                                disabled={Boolean(
                                    !navPoint.page ||
                                        !navPoint.volume ||
                                        !booksConfig[navPoint.book_name],
                                )}
                                listSubheaderLabel={navPoint.group}
                            >
                                {navPoint.label}
                                {Boolean(
                                    !navPoint.page ||
                                        !navPoint.volume ||
                                        !booksConfig[navPoint.book_name],
                                ) && ` (${t('not_available')})`}
                            </MenuItemWithListSubheader>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    function EditionSelector() {
        return (
            <Box sx={{ paddingX: isMobile ? 2 : undefined }}>
                <FormControl variant="standard">
                    <InputLabel id="quick-navigation">
                        {t('edition')}
                    </InputLabel>
                    <Select
                        size="small"
                        labelId="edition-label"
                        value={JSON.stringify(bookConfig)}
                        variant="filled"
                        label={t('edition')}
                        onChange={(event) => {
                            const parsed = JSON.parse(
                                event.target.value,
                            ) as BookInfo;

                            // we can assert that everything is non-null
                            // since we verify it's non-null in the 'disable' prop

                            const editionRef = editionsRefs!.find(
                                (ref) => ref.edition === parsed.bookCopy,
                            );

                            if (
                                !editionRef ||
                                !editionRef.page ||
                                !editionRef.volume
                            ) {
                                throw new Error(
                                    `invalid edition selector item: one of editionRef, editionRef.page, editionRef.volume is null: ${editionRef}, ${editionRef?.page}, ${editionRef?.volume}`,
                                );
                            }

                            setBookConfig(parsed);
                            setSelectedPage(editionRef.page);
                            setCurrentInputPage(editionRef.page);
                            setSelectedVolume(editionRef.volume);
                        }}
                        sx={{
                            width: isMobile ? '80vw' : undefined,
                        }}
                        MenuProps={{
                            disablePortal: true,
                        }}
                        disabled={Object.keys(editions).length === 1}
                    >
                        {Object.values(editions).map((cfg) => (
                            <MenuItem
                                value={JSON.stringify(cfg)}
                                key={cfg.bookCopy}
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'wrap',
                                }}
                                disabled={
                                    !editionRefAvailable(
                                        cfg.bookCopy,
                                        editionsRefs,
                                    )
                                }
                            >
                                {cfg.bookCopy}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    function BookDetailsDesktop() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                    gap: 1,
                    px: 2,
                }}
            >
                <Typography sx={{ fontSize: { xs: '14px', md: 'initial' } }}>
                    {bookConfig.bookAuthor}
                </Typography>
                {!isMobile && <Typography>-</Typography>}
                <EditionSelector />
            </Box>
        );
    }

    return (
        <Dialog
            dir="rtl"
            open={open}
            fullWidth
            maxWidth="lg"
            onClose={() => setOpen(false)}
            fullScreen={isMobile}
            scroll={'paper'}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 0,
                    mb: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 2,
                        pt: 1,
                        mb: { xs: 1, md: 'unset' },
                        boxShadow: {
                            xs: '0 5px 10px -2px rgba(0, 0, 0, 0.19)',
                            md: 'unset',
                        },
                        pb: { xs: '10px', md: 'unset' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isMobile && (
                            <IconButton
                                sx={{
                                    mr: 1,
                                }}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <ArrowForwardIcon color="secondary" />
                            </IconButton>
                        )}
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: '16px', md: 'initial' },
                            }}
                        >
                            {bookConfig.bookName}
                        </Typography>
                    </Box>

                    {!isMobile && (
                        <IconButton>
                            <CancelIcon
                                sx={{
                                    color: '#B7A4A4',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                fontSize="large"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            />
                        </IconButton>
                    )}
                </Box>
                {isMobile ? (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <EditionSelector />
                        {quickNavPoints.length > 1 && (
                            <QuickNavPointsSelector />
                        )}
                        <Box>
                            <Alert
                                severity="warning"
                                sx={{ paddingY: '0px', height: 'fit-content' }}
                            >
                                <span style={{ fontSize: 'x-small' }}>
                                    {t('preview_disclaimer')}
                                </span>
                            </Alert>
                        </Box>
                    </Box>
                ) : (
                    <BookDetailsDesktop />
                )}
            </DialogTitle>
            <FullScreen handle={fullScreenHandle}>
                <DialogContent
                    id="book-preview-dialog-content"
                    sx={{
                        overflowY: 'auto',
                    }}
                    // so it can be focussed on and receive keyboard events
                    tabIndex={-1}
                    onKeyDown={(event) => {
                        if (event.key === 'ArrowRight') {
                            setSelectedPage(
                                selectedPage == 1 ? 1 : selectedPage - 1,
                            );
                            setCurrentInputPage(
                                selectedPage == 1 ? 1 : selectedPage - 1,
                            );
                        } else if (event.key === 'ArrowLeft') {
                            setSelectedPage(
                                selectedPage <
                                    bookConfig.volumesPage[selectedVolume][1]
                                    ? selectedPage + 1
                                    : selectedPage,
                            );
                            setCurrentInputPage(
                                selectedPage <
                                    bookConfig.volumesPage[selectedVolume][1]
                                    ? selectedPage + 1
                                    : selectedPage,
                            );
                        }
                    }}
                >
                    <Box
                        sx={{
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}
                        {...swipeHandlers}
                    >
                        <Controls
                            selectedVolume={selectedVolume}
                            setSelectedVolume={setSelectedVolume}
                            totalVolume={bookConfig.totalVolume}
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            currentInputPage={currentInputPage}
                            setCurrentInputPage={setCurrentInputPage}
                            volumesStartEnd={bookConfig.volumesPage}
                            fullScreenHandle={fullScreenHandle}
                            previousSelectedPage={previousSelectedPage}
                            setPreviousSelectedPage={setPreviousSelectedPage}
                        />
                        {!isMobile && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    width: '100%',
                                }}
                            >
                                {quickNavPoints.length > 1 && (
                                    <QuickNavPointsSelector />
                                )}
                                <Alert severity="warning" sx={{ mb: 1 }}>
                                    <span style={{ fontSize: 'medium' }}>
                                        {t('preview_disclaimer')}
                                    </span>
                                </Alert>
                            </Box>
                        )}
                        <Box
                            sx={{
                                border: '5px solid black',
                                pointerEvents: 'none',
                                width: '100%',
                            }}
                        >
                            {images}
                        </Box>
                    </Box>
                </DialogContent>
            </FullScreen>
        </Dialog>
    );
};

export default BookPreview;
