import { Box, Tooltip, TooltipProps, Typography } from '@mui/material';

type Placement = TooltipProps['placement'];

export function CustomTooltip({
    message,
    placement = 'top',
    ...props
}: Partial<TooltipProps> & { message?: string }) {
    return (
        <Tooltip
            placement={placement}
            // only display for disabled tabs
            title={
                message ? <Typography fontSize={12}>{message}</Typography> : ''
            }
            // no long press, immediate
            enterTouchDelay={1}
            {...props}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    zIndex: 1,
                }}
            ></Box>
        </Tooltip>
    );
}
