import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import BareBonesHadith from '@components/results/items/BareBonesHadith';
import { HadithContainer } from '@components/results/items/HadithResultItem/styled';
import {
    getBookAndIdTextToggle,
    getHadithSubtitle,
    getHadithWithTarqeemToggled,
    getHadithWithTashkeelToggled,
    getVolumeAndPageText,
    htmlToReactParserOptions,
} from '@components/results/items/helpers';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import useTranslation from '@hooks/useTranslation';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { WordConfig } from '@utils/export';
import {
    getTakhreej,
    narrationsNumbersToRawNarrations,
} from '@utils/narrations';

import MainModal from './MainModal';
import {
    type NarratorHadith,
    type RoadsHadith,
    type RulingsHadith,
    type SearchPageHadith,
} from 'shared/interfaces/hadith';
import { colors } from 'theme';

const CopyHadithModal = ({
    onClose,
    setShowSnackbar,
    hadithHtml,
    hadith,
    title,
    downloadText,
    onWordExport,
}: {
    onClose: () => void;
    setShowSnackbar?: (props: MySnackbarProps) => void;
    hadithHtml: string;
    // this type isn't accurate, need to use generic hadith instead, TODO [@chammaaomar]
    hadith: SearchPageHadith | RoadsHadith | NarratorHadith | RulingsHadith;
    title: string;
    downloadText: string;
    onWordExport?: (config: WordConfig) => void;
}) => {
    const { t } = useTranslation('library');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const router = useRouter();

    const [tashkeel, setTashkeel] = useState(true);
    const [tarqeem, setTarqeem] = useState(true);
    const [number, setNumber] = useState(hadith.number.length > 0);
    const [book, setBook] = useState(true);
    const [chapter, setChapter] = useState(false);
    const [subChapter, setSubChapter] = useState(false);
    const [volume, setVolume] = useState(false);
    const [page, setPage] = useState(false);
    const [takhreej, setTakhreej] = useState(false);
    const [rulings, setRulings] = useState(false);

    hadithHtml = getHadithWithTashkeelToggled(tashkeel, hadithHtml);
    hadithHtml = getHadithWithTarqeemToggled(tarqeem, hadithHtml);

    const bookAndIdText = getBookAndIdTextToggle(
        book,
        number,
        hadith.book_name,
        hadith.number[0],
    );

    const hadithOptions = htmlToReactParserOptions(
        hadith.narrators,
        [],
        router,
        undefined,
    );

    const takhreejArr =
        'narrations_numbers' in hadith
            ? getTakhreej(
                  narrationsNumbersToRawNarrations(hadith.narrations_numbers),
              )
            : [];

    const wordSampleDisclaimer =
        'النص أدناه هو مثال على طريقة عرض الأحاديث في الملف المستخرج';

    const hadithItem = (
        <BareBonesHadith
            hadithHtml={hadithHtml}
            book={bookAndIdText}
            chapter={chapter ? hadith.chapter : ''}
            subChapter={subChapter ? hadith.sub_chapter ?? '' : ''}
            page={page ? hadith.page ?? 0 : 0}
            volume={volume ? hadith.volume ?? 0 : 0}
            pageVolumePlacement="body"
            type=""
            hadithOptions={hadithOptions}
            hadith_numbers={hadith.number}
            headerText={onWordExport ? wordSampleDisclaimer : ''}
            titleDivider={false}
            hideHeaderInfoNumber
            takhreej={takhreej ? takhreejArr : undefined}
            rulings={
                rulings && 'rulings' in hadith && hadith.rulings.length > 0
                    ? hadith.rulings
                    : undefined
            }
            applyTashkeel={false}
        />
    );

    const onCopy = () => {
        const copyBookAndSerialId = bookAndIdText ? bookAndIdText + '\n' : '';
        const copyPageAndVolume = getVolumeAndPageText(
            volume,
            page,
            hadith.volume,
            hadith.page,
        );
        const copySubtitle =
            chapter || subChapter
                ? getHadithSubtitle(
                      chapter ? hadith.chapter : '',
                      subChapter ? hadith.sub_chapter ?? '' : '',
                  ) + '\n'
                : '';
        const copyTakhreej =
            takhreej && takhreejArr.length > 0
                ? takhreejArr.join('\n') + '\n'
                : '';
        const copyRulings =
            rulings && 'rulings' in hadith
                ? hadith.rulings
                      .map((rul) => {
                          let ruling = `${rul.ruler}: ${rul.ruling}\n`;
                          if (rul.book_name && rul.page && rul.volume) {
                              ruling += `${rul.book_name}: (${rul.page}/${rul.volume})\n`;
                          }
                          return ruling;
                      })
                      .join('\n')
                : '';

        navigator.clipboard.writeText(
            copyBookAndSerialId +
                copyPageAndVolume +
                copySubtitle +
                hadithHtml +
                '\n' +
                copyRulings +
                copyTakhreej,
        );

        if (setShowSnackbar) {
            setShowSnackbar({
                show: true,
                message: t('hadith_copied'),
            });
        }

        onClose();
    };
    type SettingName =
        | 'book'
        | 'number'
        | 'theChapter'
        | 'subChapter'
        | 'volume'
        | 'page'
        | 'tashkeel'
        | 'tarqeem'
        | 'takhreej'
        | 'rulings';
    type Setting = {
        setting: boolean;
        name: SettingName;
        setSetting: (x: boolean) => void;
        disabled?: boolean;
    };
    const settings: Setting[] = [
        {
            setting: book,
            name: 'book',
            setSetting: setBook,
        },
        {
            setting: number,
            name: 'number',
            setSetting: setNumber,
            disabled: !hadith.number.length,
        },
        {
            setting: volume,
            name: 'volume',
            setSetting: setVolume,
            disabled: !hadith.volume,
        },
        {
            setting: page,
            name: 'page',
            setSetting: setPage,
            disabled: !hadith.page,
        },
        {
            setting: chapter,
            name: 'theChapter',
            setSetting: setChapter,
            disabled: !hadith.chapter,
        },
        {
            setting: subChapter,
            name: 'subChapter',
            setSetting: setSubChapter,
            disabled:
                !hadith.sub_chapter || hadith.sub_chapter === hadith.chapter,
        },
        {
            setting: tashkeel,
            name: 'tashkeel',
            setSetting: setTashkeel,
        },
        {
            setting: tarqeem,
            name: 'tarqeem',
            setSetting: setTarqeem,
        },
        {
            setting: takhreej,
            name: 'takhreej',
            setSetting: setTakhreej,
            disabled:
                // !('narrations_numbers' in hadith) ||
                // ('narrations_numbers' in hadith &&
                //     hadith.narrations_numbers.length === 0),
                true,
        },
    ];

    if ('rulings' in hadith) {
        settings.push({
            setting: rulings,
            name: 'rulings',
            setSetting: setRulings,
            disabled: !('rulings' in hadith && hadith.rulings.length > 0),
        });
    }

    useEffect(() => {
        const copyResultContainer = document.getElementById(
            `hadith-${hadith.hadith_id}-copy-config`,
        );
        if (copyResultContainer) {
            // if takhreej, scroll to bottom, otherwise go back to top
            copyResultContainer.scrollTo({
                top: takhreej ? 1_000_000 : 0,
                behavior: 'smooth',
            });
        }
    }, [takhreej]);

    const wordConfig: WordConfig = {
        book,
        number,
        volume,
        page,
        chapter,
        subChapter,
        tashkeel,
        takhreej,
        tarqeem,
        rulings,
    };

    const CopyHadithButton = (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                    onWordExport ? onWordExport(wordConfig) : onCopy()
                }
                sx={{
                    width: isMobile ? '100%' : '25%',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: colors.secondary,
                        opacity: 0.9,
                    },
                }}
            >
                {downloadText}
            </Button>
        </Box>
    );

    return (
        <MainModal
            handleClose={onClose}
            title={title}
            moveTitleToRight={isMobile}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    height: isMobile ? 'calc(100vh - 72px)' : '100%',
                }}
            >
                <Alert severity="warning">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('in_progress_takhreej_feature'),
                        }}
                    ></span>
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid
                        container
                        sx={{
                            width: isMobile ? '100%' : '20%',
                        }}
                    >
                        {settings.map(
                            ({ setting, name, setSetting, disabled }) => (
                                <Grid
                                    item
                                    xs={6}
                                    sm={12}
                                    key={`copy-settings-${name}`}
                                >
                                    <FormControlLabel
                                        label={t(name)}
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={setting}
                                                onChange={(event) =>
                                                    setSetting(
                                                        event.target.checked,
                                                    )
                                                }
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                </Grid>
                            ),
                        )}
                    </Grid>
                    {isMobile && CopyHadithButton}
                    <HadithContainer
                        elevation={0}
                        variant="outlined"
                        sx={{
                            margin: { xs: '10px 0', md: '10px 0' },
                            width: isMobile ? '100%' : '80%',
                            // not ideal since it doesn't guarnatee that the "Copy" button
                            // is always at the bottom and not pushed into scroll
                            // but this works for now
                            height: '400px',
                            overflowY: 'auto',
                        }}
                        id={`hadith-${hadith.hadith_id}-copy-config`}
                    >
                        {hadithItem}
                    </HadithContainer>
                </Box>
                {!isMobile && CopyHadithButton}
            </Box>
        </MainModal>
    );
};

export default CopyHadithModal;
