import React, { createContext, useContext, useEffect, useState } from 'react';

interface TashkeelContextType {
    tashkeelEnabled: boolean;
    toggleTashkeel: () => void;
}

const TashkeelContext = createContext<TashkeelContextType | undefined>(
    undefined,
);

export const TashkeelProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [tashkeelEnabled, setTashkeelEnabled] = useState<boolean>(true);

    useEffect(() => {
        const t = localStorage.getItem('tashkeelEnabled');
        if (t) {
            setTashkeelEnabled(JSON.parse(t) as boolean);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(
            'tashkeelEnabled',
            JSON.stringify(tashkeelEnabled),
        );
    }, [tashkeelEnabled]);

    const toggleTashkeel = () => {
        setTashkeelEnabled((prev) => !prev);
    };

    return (
        <TashkeelContext.Provider value={{ tashkeelEnabled, toggleTashkeel }}>
            {children}
        </TashkeelContext.Provider>
    );
};

export const useTashkeel = (): TashkeelContextType => {
    const context = useContext(TashkeelContext);
    if (context === undefined) {
        throw new Error('useTashkeel must be used within a TashkeelProvider');
    }
    return context;
};
