import { useEffect, useRef, useState } from 'react';

import type { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CommonContextProvider } from '@hooks/commonContext';
import { FontSizeProvider } from '@hooks/fontSizeContext';
import { TashkeelProvider } from '@hooks/tashkeelContext';
import { LanguageContextProvider } from '@hooks/translationContext';
import Hotjar from '@hotjar/browser';
import { BottomNav } from '@layout/BottomNav';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

import './styles.css';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import theme from 'theme';

const App = ({ Component, pageProps }: AppProps) => {
    const [queryClient] = useState(() => new QueryClient());
    const hotjarInit = useRef(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    useEffect(() => {
        if (!hotjarInit.current) {
            const siteId = 5085057;
            const hotjarVersion = 6;

            Hotjar.init(siteId, hotjarVersion);
            hotjarInit.current = true;
        }
    }, []);

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <TashkeelProvider>
                    <FontSizeProvider>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <CssBaseline />
                                <LanguageContextProvider>
                                    <CommonContextProvider>
                                        <Component {...pageProps} />
                                        {isMobile ? (
                                            <BottomNav key="bottom-nav-mobile" />
                                        ) : null}
                                        <SpeedInsights />
                                        <Analytics />
                                    </CommonContextProvider>
                                </LanguageContextProvider>
                            </Hydrate>
                        </QueryClientProvider>
                    </FontSizeProvider>
                </TashkeelProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};
export default App;
