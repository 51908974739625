import { ComponentProps, DOMAttributes, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import {
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import MainModal from './MainModal';
import { ZodError, z } from 'zod';

type OnChange = ComponentProps<typeof TextField>['onChange'];
type OnSubmit = DOMAttributes<Element>['onSubmit'];

const ReportErrorModal = ({
    handleClose,
    sendSubmissionData,
    headerText,
}: {
    handleClose: () => void;
    sendSubmissionData: (x: any) => Promise<boolean>;
    headerText?: string;
}) => {
    const { t } = useTranslation('library');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);

    const defaultForm = {
        fullName: '',
        email: '',
        message: '',
    };
    const [form, setForm] = useState(defaultForm);
    const [errors, setErrors] = useState(defaultForm);

    const formData = z.object({
        fullName: z.string().min(1, t('required_field')),
        email: z.string().email({ message: t('invalid_email') }),
        message: z.string().min(1, t('required_field')),
    });

    const handleChange: OnChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit: OnSubmit = async () => {
        setLoading(true);
        try {
            formData.parse(form);
            const result = await sendSubmissionData(form);
            if (result) {
                // success; clear out the submission fields
                setForm(defaultForm);
            }
        } catch (error) {
            if (error instanceof ZodError) {
                const issues = error.issues;
                let errors = defaultForm;
                issues.forEach((issue) => {
                    errors = { ...errors, [issue.path[0]]: issue.message };
                });
                setErrors(errors);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainModal
            handleClose={handleClose}
            title={headerText || t('report_an_error')}
            moveTitleToRight={isMobile}
            isFullWidth={isMobile}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{
                    '& .MuiFormHelperText-root': { textAlign: 'start' },
                }}
            >
                <Typography variant="body1" textAlign="start">
                    {t('report_an_error_description')}
                </Typography>
                <TextField
                    placeholder={t('label_full_name')}
                    name="fullName"
                    value={form.fullName}
                    onChange={handleChange}
                    error={!!errors.fullName}
                    helperText={errors.fullName}
                />
                <TextField
                    placeholder={t('email')}
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    multiline
                    rows={4}
                    placeholder={t('your_message')}
                    name="message"
                    value={form.message}
                    onChange={handleChange}
                    error={!!errors.message}
                    helperText={errors.message}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    {t('send')}
                </Button>
            </Box>
        </MainModal>
    );
};

export default ReportErrorModal;
