import type { ReactNode } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import Modal from './Modal';
import { GenericHadith } from 'shared/interfaces/hadith';

const MainModal = ({
    moveTitleToRight = false,
    children,
    title = '',
    handleClose,
    isFullWidth,
    containerId,
    originalHadith,
}: {
    moveTitleToRight?: boolean;
    children: ReactNode;
    title?: string;
    handleClose: () => void;
    isFullWidth?: boolean;
    containerId?: string;
    originalHadith?: GenericHadith;
}) => {
    const theme = useTheme();

    return (
        <Modal
            handleClose={handleClose}
            isFullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
            title={title}
            moveTitleToRight={moveTitleToRight}
            isFullWidth={isFullWidth}
            containerId={containerId}
            originalHadith={originalHadith}
        >
            {children}
        </Modal>
    );
};

export default MainModal;
