import { createContext, useContext, useEffect, useState } from 'react';

export interface FontSizeObject {
    title: string;
    subtitle: string;
    body: string;
}

export enum FontSizes {
    Small = 'Small',
    Normal = 'Normal',
    Large = 'Large',
    VeryLarge = 'VeryLarge',
}

export const fontSizeValues: Record<FontSizes, FontSizeObject> = {
    [FontSizes.Small]: {
        title: '0.875rem',
        subtitle: '0.75rem',
        body: '0.625rem',
    },
    [FontSizes.Normal]: { title: '1.25rem', subtitle: '1rem', body: '0.95rem' },
    [FontSizes.Large]: {
        title: '1.5rem',
        subtitle: '1.25rem',
        body: '1.1rem',
    },
    [FontSizes.VeryLarge]: {
        title: '1.7rem',
        subtitle: '1.325rem',
        body: '1.2rem',
    },
};

interface FontSizeContextType {
    fontSize: FontSizeObject;
    setFontSize: (size: FontSizeObject) => void;
}

const FontSizeContext = createContext<FontSizeContextType | undefined>(
    undefined,
);

export const FontSizeProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [fontSize, setFontSize] = useState<FontSizeObject>(
        fontSizeValues[FontSizes.Normal],
    );

    useEffect(() => {
        const storedFontSize = localStorage.getItem('fontSize');
        if (
            storedFontSize &&
            Object.keys(fontSizeValues).includes(storedFontSize)
        ) {
            setFontSize(fontSizeValues[storedFontSize as FontSizes]);
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
            if (
                e.key === 'fontSize' &&
                e.newValue &&
                Object.keys(fontSizeValues).includes(e.newValue)
            ) {
                setFontSize(fontSizeValues[e.newValue as FontSizes]);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        const fontSizeKey = Object.keys(fontSizeValues).find(
            (key) => fontSizeValues[key as FontSizes] === fontSize,
        );
        if (fontSizeKey) {
            localStorage.setItem('fontSize', fontSizeKey);
        }
    }, [fontSize]);

    return (
        <FontSizeContext.Provider value={{ fontSize, setFontSize }}>
            {children}
        </FontSizeContext.Provider>
    );
};

export const useFontSize = () => {
    const context = useContext(FontSizeContext);
    if (!context) {
        throw new Error('useFontSize must be used within a FontSizeProvider');
    }
    return context;
};
