import { Box, Paper, styled } from '@mui/material';

export const HadithContainer = styled(Paper)({
    borderRadius: '10px',
});

export const HadithHeaderInfo = styled(Box)({
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
});

export const HadithHeaderInfoNumber = styled(Box)(({ theme: { palette } }) => ({
    display: 'flex',
    direction: 'rtl',
    alignItems: 'center',
    width: 'max-content',
    padding: '5px',
    borderBottomRightRadius: '5px',
    borderBottom: `1px solid ${palette.divider}`,
    borderRight: `1px solid  ${palette.divider}`,
}));

export const HadithHeaderInfoDetails = styled(Box)(
    ({ theme: { palette } }) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: 'max-content',
        borderBottomLeftRadius: '5px',
        borderBottom: `1px solid ${palette.divider}`,
        borderLeft: `1px solid ${palette.divider}`,
    }),
);

export const HadithTitleContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'right',
    flexDirection: 'column',
    paddingLeft: 10,
    marginBottom: 15,
}));

export const HadithTextContainer = styled(Box)(() => ({
    padding: '10px',
    textAlign: 'justify',
}));

export const HadithRulingContainer = styled(Box)(() => ({
    paddingTop: '10px',
    marginRight: '10px',
    marginLeft: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'justify',
}));

export const HadithMatnComparisonContainer = styled(Box)(() => ({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'justify',
}));

export const HadithActionsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

export const HadithActionsContainer = styled(Box)(() => ({
    height: '100%',
    width: '100%',
}));
