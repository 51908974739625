export const MoreIcon = () => {
    return (
        <svg
            width="21"
            height="17"
            viewBox="0 0 21 14"
            fill="none"
            style={{
                marginBottom: '8px',
            }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.25 12H19.25C19.8023 12 20.25 12.4477 20.25 13C20.25 13.5128 19.864 13.9355 19.3666 13.9933L19.25 14H1.25C0.697715 14 0.25 13.5523 0.25 13C0.25 12.4872 0.63604 12.0645 1.13338 12.0067L1.25 12H19.25H1.25ZM1.24988 6L19.2499 5.99783C19.8022 5.99777 20.25 6.44543 20.25 6.99771C20.25 7.51055 19.8641 7.93327 19.3667 7.99109L19.2501 7.99783L1.25012 8C0.697836 8.00007 0.25 7.5524 0.25 7.00012C0.25 6.48728 0.635936 6.06457 1.13326 6.00674L1.24988 6L19.2499 5.99783L1.24988 6ZM1.25 0H19.25C19.8023 0 20.25 0.447715 20.25 1C20.25 1.51284 19.864 1.93551 19.3666 1.99327L19.25 2H1.25C0.697715 2 0.25 1.55228 0.25 1C0.25 0.487164 0.63604 0.0644928 1.13338 0.0067277L1.25 0H19.25H1.25Z"
                fill="#B7A4A4"
            />
        </svg>
    );
};
