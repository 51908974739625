import { forwardRef } from 'react';

import CheckIcon from '@components/icons/Checkmark';

import ErrorIcon from '@mui/icons-material/Error';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

export interface MySnackbarProps extends SnackbarProps {
    severity?: AlertProps['severity'];
    show?: boolean;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackbar = ({ message, severity, ...snackProps }: MySnackbarProps) => {
    return (
        <Snackbar open {...snackProps}>
            <Alert
                severity={severity ?? 'success'}
                sx={{
                    width: '100%',
                    display: 'flex',
                    gap: 2,
                    mb: { xs: '20px', sm: 'unset' },
                }}
                icon={
                    severity === 'error' ? (
                        <ErrorIcon fontSize="small" />
                    ) : (
                        <CheckIcon fontSize="small" />
                    )
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default MySnackbar;
