import { useContext } from 'react';

import { Translation } from '../../locales/translationsEn';
import { LanguageContext } from './translationContext';

const useTranslation = <Section extends keyof Translation>(
    section: Section,
) => {
    const { translations } = useContext(LanguageContext);

    const t = (
        // only string keys, not numbers or symbols
        subObject: Extract<keyof Translation[Section], string>,
    ): string => {
        const subDictionary = translations[section];
        const translation = subDictionary[subObject];
        return (
            (translation as string) ??
            (section ? section + '.' + subObject : subObject)
        );
    };

    return { t };
};

export default useTranslation;
