import { track } from '@vercel/analytics';

type CustomEvent =
    | 'hadith'
    | 'hadith-tabs'
    | 'narrator-tabs'
    | 'roads-tabs'
    | 'book-preview'
    | 'word-export'
    | 'excel-export'
    | 'copy'
    | 'search';

export function analytics(
    event: CustomEvent,
    labels: Record<string, string>,
): void {
    track(event, labels);
}
