import { e2a } from './arabicToEnglishNumbers';
import { authenticity_order } from './sortBooks';
import { SearchPageHadith } from 'shared/interfaces/hadith';

export const bookIdMap = {
    '184': 'سنن أبي داود',
    '121': 'مسند أحمد بن حنبل',
    '146': 'صحيح البخاري',
    '137': 'سنن الدارمي',
    '173': 'سنن ابن ماجه',
    '158': 'صحيح مسلم',
    '319': 'سنن النسائى الصغرى',
    '195': 'جامع الترمذي',
    '19': 'موطأ مالك رواية يحيى الليثي',
    '454': 'صحيح ابن حبان',
    '345': 'صحيح ابن خزيمة',
    '594': 'المستدرك على الصحيحين',
} as const;

export type HadithBooksId = keyof typeof bookIdMap;

export function narrationsNumbersToRawNarrations(
    narrations_numbers: SearchPageHadith['narrations_numbers'],
): string[] {
    return narrations_numbers.flatMap((numObj) =>
        // this function simply prepends the bookId to the hadith numbers
        // because the .number array is missing the bookId; it only has the hadith number
        // in the book
        numObj.number.map((num) => {
            const bookId = numObj.narration_id.split('-')[0] as string;
            return `${bookId}-${num}`;
        }),
    );
}

// book_name1 (narration_id_1, narration_id_2, ...)
// book_name2 (narration_id_1, narration_id_2, ...)
// ...
// ordered by authenticity
export function getTakhreej(narrations: string[]): string[] {
    const sortedByAuth = narrations.sort((narr1, narr2) => {
        const [bookId1, narrId1] = narr1.split('-') as [HadithBooksId, string];
        const [bookId2, narrId2] = narr2.split('-') as [HadithBooksId, string];

        const bookName1 = bookIdMap[bookId1];
        const bookName2 = bookIdMap[bookId2];

        if (authenticity_order[bookName1] > authenticity_order[bookName2]) {
            return 1;
        } else if (
            authenticity_order[bookName1] < authenticity_order[bookName2]
        ) {
            return -1;
        } else {
            // equal
            return parseInt(narrId1, 10) > parseInt(narrId2, 10) ? 1 : -1;
        }
    });

    const aggByBook = sortedByAuth.reduce((acc, curr, index) => {
        const prev = index > 0 ? sortedByAuth[index - 1] : '';
        const prevBookId = prev.split('-')[0];
        const currBookId = curr.split('-')[0];
        if (prevBookId === currBookId) {
            const lastBook: string[] = acc[acc.length - 1];
            lastBook.push(curr);
            return acc;
        } else {
            acc.push([curr]);
            return acc;
        }
    }, [] as string[][]);

    return aggByBook.map((agg) => {
        const bookId = agg[0].split('-')[0] as HadithBooksId;
        const bookName = bookIdMap[bookId];
        const narrIds = agg.map((narr) => {
            const arabicNumeralsId = narr.split('-')[1];
            const indianNumeralsId = arabicNumeralsId
                .split('')
                .map((digit) => e2a(parseInt(digit, 10)));
            return indianNumeralsId.join('');
        });
        return `${bookName} - ( ${narrIds.join('، ')} )`;
    });
}
