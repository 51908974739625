import {
    type PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

import { translationsAr } from '../../locales/translationsAr';
import { Translation, translationsEn } from '../../locales/translationsEn';

interface LangContext {
    language: string;
    setLanguage: (lang: 'ar' | 'en') => void;
    translations: Translation;
}

export const LanguageContext = createContext<LangContext>({
    language: 'ar',
    setLanguage: () => {},
    translations: translationsAr,
});

export function useTranslationContext(): LangContext {
    return useContext(LanguageContext);
}

export function LanguageContextProvider({
    children,
}: PropsWithChildren<unknown>) {
    const [language, setLanguage] = useState<'ar' | 'en'>('ar');
    const [translations, setTranslations] =
        useState<Translation>(translationsAr);

    const getTranslationObject = (language: 'ar' | 'en') => {
        return language === 'ar' ? translationsAr : translationsEn;
    };

    useEffect(() => {
        setTranslations(getTranslationObject(language));
    }, [language]);

    return (
        <LanguageContext.Provider
            value={{ language, setLanguage, translations }}
        >
            {children}
        </LanguageContext.Provider>
    );
}
