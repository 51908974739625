import { useRouter } from 'next/router';

import { HadithViews, NarratorViews, RoadsViews } from 'constants/views';
import { ParsedUrlQuery } from 'querystring';

const HADITH_ID = 'hadithId';
const HADITH_VIEW = 'hadithView';
const NARRATOR_ID = 'narratorId';
const NARRATOR_VIEW = 'narratorView';
const ROADS_VIEW = 'roadsView';

export enum ROUTING {
    HADITH_PAGE_ROADS = '@routing/HADITH_PAGE_ROADS',
    HADITH_PAGE_NARRATOR = '@routing/HADITH_PAGE_NARRATOR',
    NARRATOR_PAGE = '@routing/NARRATOR_PAGE',
    AMBIGUOUS_PAGE = '@routing/AMBIGUOUS_PAGE',
}

/**
 * @description routing for narrators
 * @info list comes from next/router {query.param}
 * @example '123/narrator/456/bio'
 */
const HADITH_PAGE_NARRATOR_PARAMS = [
    HADITH_ID,
    HADITH_VIEW,
    NARRATOR_ID,
    NARRATOR_VIEW,
];

/**
 * @description routing for roads
 * @info list comes from next/router {query.param}
 * @example '123/roads/graph'
 */
const HADITH_PAGE_ROADS_PARAMS = [HADITH_ID, HADITH_VIEW, ROADS_VIEW];

/**
 * @description routing for narrator page
 * @info list comes from next/router {query.param}
 * @example '123/bio'
 */
const NARRATOR_PAGE_PARAMS = [NARRATOR_ID, NARRATOR_VIEW];

export const findParamKeysByRouting = (routing: ROUTING) => {
    if (routing === ROUTING.HADITH_PAGE_ROADS) {
        return HADITH_PAGE_ROADS_PARAMS;
    } else if (routing === ROUTING.NARRATOR_PAGE) {
        return NARRATOR_PAGE_PARAMS;
    }

    return HADITH_PAGE_NARRATOR_PARAMS;
};

export interface GraphParams {
    hadithIds?: string | string[];
    canvasSize?: string;
    isCanvasHidden?: string;
    useNeo?: string;
}
export interface IParams extends GraphParams {
    hadithId?: string;
    hadithView?: HadithViews;
    narratorId?: string;
    hadithNarratorId?: string;
    narratorView?: NarratorViews;
    hadithNarratorView?: NarratorViews;
    ambg_id?: string;
    roadsView?: RoadsViews;
}

/**
 * @description finds the param id and views from next/router query.param or params from contextual routing
 * @default finds params for HADITH_PAGE_NARRATOR_PARAMS
 * @example (['123', 'narrator', '456', 'bio']) => ({hadithId: 123, narratorId: 456,narratorView: 'bio', hadithView: 'narrator'})
 * @note if this hook is used server side then it must be provided with query
 */
const useParams: (
    routing?: ROUTING,
    query?: ParsedUrlQuery,
) => IParams = () => {
    const router = useRouter();
    return router.query;
};

export default useParams;
