import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: ['Readex Pro', 'MunaBlack', 'sans-serif'].join(','),
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: '#C7956D',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#632626',
        },
        secondary: {
            main: '#C7956D',
        },
        text: {
            primary: '#461111',
        },
    },
    direction: 'rtl',
    spacing: 8,
    shape: {
        borderRadius: 4,
    },
});

const colors = {
    primary: '#632626',
    secondary: '#C7956D',
    tertiary: '#965D62',
    hover: 'rgba(183, 164, 164, 0.1);',
};

export { theme as default, colors };
